export const Partners = [
	{
		id: "HPV",
		name: "Home Pro Valet",
		logo: "/assets/HPV.svg",
		footerCopy: {
			line1: "© 2025 Home Pro Valet.",
			line2: "All Rights Reserved.",
			line3: "For questions email info@homeprovalet.com.",
		},
		jobRequestCopy: "Request a Home Pro",
		vendorListHeading: "Home Pro Categories",
		vendorListSubHeading:
			"Review the 50+ categories we serve and request to be connected with a Home Pro.",
		vendorNavText: "Home Pros",
		vendorTextSingular: "Home Pro",
		contactEmail: "info@homeprovalet.com",
		jobRequestHeader: "Job Requests",
		jobRequestText: "job requests",
	},
	{
		id: "BDPS",
		name: "Black Dome Services",
		logo: "/assets/BDPS.svg",
		footerCopy: {
			line1: "© 2025 Black Dome Property Services.",
			line2: "All Rights Reserved.",
			line3: "For questions email support@blackdomeservices.com",
		},
		jobRequestCopy: "Create work order",
		vendorListHeading: "Vendor Categories",
		vendorListSubHeading:
			"Review the 125+ categories we serve and request to be connected with a Vendor.",
		vendorNavText: "Vendors",
		vendorTextSingular: "Vendor",
		contactEmail: "support@blackdomeservices.com",
		jobRequestHeader: "Work Orders",
		jobRequestText: "work orders",
	},
]
