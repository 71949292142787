import React, { useState } from "react"
import { Image, Tooltip } from "@chakra-ui/react"

export const ReferralBadge = ({
	id,
	couponCode,
	couponAmount,
	couponPercent,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [openTooltipId, setOpenTooltipId] = useState(null)

	const onOpen = () => {
		setIsOpen(true)
		setOpenTooltipId(id)
	}

	const onClose = () => {
		setIsOpen(false)
		setOpenTooltipId(null)
	}

	const onToggle = () => {
		setIsOpen(!isOpen)
		setOpenTooltipId(isOpen ? null : id)
	}

	const shouldRenderTooltip = openTooltipId === id

	return (
		<Tooltip
			label="This client was a referral from another client"
			fontSize="md"
			isOpen={shouldRenderTooltip && isOpen}
		>
			<Image
				src="/assets/referral_icon.svg"
				onMouseEnter={onOpen}
				onMouseLeave={onClose}
				onClick={onToggle}
				my="auto"
			/>
		</Tooltip>
	)
}
