import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Heading,
	HStack,
	Icon,
	Text,
	VStack,
	useToast,
} from "@chakra-ui/react"
import { AttachmentIcon } from "@chakra-ui/icons"
import { dateToFormat } from "../Utilities"

export const JobRequestResponseJobDetailsAccordion = ({ jobRequest }) => {
	const toast = useToast()
	const formatTime = (timeString) => {
		const [hourString, minute] = timeString.split(":")
		const hour = +hourString % 24
		return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM")
	}

	const handleJobRequestDocuments = () => {
		jobRequest.files.forEach((file) => {
			window.open(file, "_blank")
		})
		return toast({
			title:
				"If you’re unable to view attachments, disable your pop-up blocker.",
			status: "loading",
			duration: 6000,
			isClosable: true,
		})
	}

	return (
		<Accordion allowToggle mb="20px">
			<AccordionItem>
				<Heading>
					<AccordionButton>
						<Box as="span" flex="1" textAlign="left" color="blue.500">
							View Job Request Details
						</Box>
						<AccordionIcon />
					</AccordionButton>
				</Heading>
				<AccordionPanel pb={4} pl={0} pr={0}>
					<Card>
						<CardBody>
							<CardHeader size="md" fontWeight="bold" pl="0px">
								{jobRequest?.locationAddress}
							</CardHeader>
							<VStack align="space-between" spacing={5}>
								<Box display="flex" justifyContent="space-between">
									<Text fontWeight="semibold">Estimated Sq Ft:</Text>
									<Text> {jobRequest?.estimatedSquareFeet}</Text>
								</Box>

								<Box display="flex" justifyContent="space-between">
									<Text fontWeight="semibold">Is Property Occupied?</Text>
									<Text> {jobRequest?.isPropertyOccupied ? "yes" : "no"}</Text>
								</Box>
								<Box display="flex" justifyContent="space-between">
									<Text fontWeight="semibold">Are utilities on?</Text>
									<Text> {jobRequest?.areUtilitiesOn ? "yes" : "no"}</Text>
								</Box>
								<Box display="flex" justifyContent="space-between">
									<Text fontWeight="semibold">Is Deal Under Contract?</Text>
									<Text> {jobRequest?.isDealUnderContract ? "yes" : "no"}</Text>
								</Box>

								{jobRequest?.inspectionPeriod && (
									<Box display="flex" justifyContent="space-between">
										<Text fontWeight="semibold">Inspection Date:</Text>
										<Text> {dateToFormat(jobRequest?.inspectionPeriod)}</Text>
									</Box>
								)}
								{jobRequest?.closingDate && (
									<Box display="flex" justifyContent="space-between">
										<Text fontWeight="semibold">Closing Date:</Text>
										<Text> {dateToFormat(jobRequest?.closingDate)}</Text>
									</Box>
								)}

								<Box>
									<Text fontWeight="semibold">Job Notes:</Text>
									<Text>{jobRequest?.jobRequestDetails}</Text>
								</Box>
								{jobRequest?.timePreference && (
									<Box display="flex" justifyContent="space-between">
										<Text fontWeight="semibold">Scheduling Preference:</Text>
										<Text>
											{" "}
											{jobRequest?.timePreference === "startWork"
												? "Schedule Work"
												: "Get Estimates"}
										</Text>
									</Box>
								)}
								<Box>
									<Text fontWeight="semibold">Service Preferred Dates:</Text>
									{jobRequest?.servicePreferredDates.map((entry, index) => (
										<HStack gap={5} mb="5px" key={index}>
											<Text key={index}>{dateToFormat(entry.date)}</Text>
											<Text>
												{formatTime(entry.startTime)} -{" "}
												{formatTime(entry.endTime)}
											</Text>
										</HStack>
									))}
								</Box>
								{jobRequest?.files && (
									<Button
										leftIcon={<Icon as={AttachmentIcon} />}
										bg="borderColor"
										color="white"
										textDecoration="none"
										borderRadius="full"
										_hover={{ bgColor: "borderColor" }}
										mt={2}
										onClick={() => handleJobRequestDocuments()}
									>
										View {jobRequest.files.length}{" "}
										{jobRequest.files.length > 1 ? "attachments" : "attachment"}
									</Button>
								)}
							</VStack>
						</CardBody>
					</Card>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}
