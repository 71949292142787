import axios from "axios"
import {
	addDoc,
	collection,
	updateDoc,
	increment,
	getDocs,
	query,
	where,
} from "firebase/firestore"
import { db } from "../firebase"
import config from "../config"
import { HPVServicesAndFees } from "../Options/HPVServicesAndFees"
import { HPV_DEFAULT_VENDOR_ID } from "../enums"

export const assignJobToHPVVendor = async (jobRequest, dateTimes, userPlus) => {
	let userEmail
	let userId

	const jobRequestData = {
		...jobRequest,
		servicePreferredDates: [
			...jobRequest.servicePreferredDates,
			...(dateTimes.date !== null &&
			dateTimes.startTime !== "" &&
			dateTimes.endTime !== ""
				? [dateTimes]
				: []),
		],
		areUtilitiesOn:
			jobRequest.areUtilitiesOn !== null ? jobRequest.areUtilitiesOn : true,
		isPropertyOccupied:
			jobRequest.isPropertyOccupied !== null
				? jobRequest.isPropertyOccupied
				: true,
		isDealUnderContract:
			jobRequest.isDealUnderContract !== null
				? jobRequest.isDealUnderContract
				: false,
	}

	if (jobRequest.clientIds.length > 0) {
		const q = query(
			collection(db, "users"),
			where("id", "==", jobRequest.clientIds[0])
		)
		const docSnap = await getDocs(q)
		if (!docSnap.empty) {
			userEmail = docSnap.docs[0].data().email
			userId = docSnap.docs[0].data().id
		}
	}

	if (userPlus.userType === "agent" && jobRequest.clientIds.length === 0) {
		userEmail = userPlus.email
		userId = userPlus.id
	}
	if (userPlus.userType === "client") {
		userEmail = userPlus.email
		userId = userPlus.id
	}

	try {
		// if client/agent is sponsored the vendor gets job
		const userIds = Array.isArray(userId) ? userId : [userId]

		const sponsoredByQuery = query(
			collection(db, "users"),
			where("id", "in", userIds),
			where("sponsoredBy", "!=", "")
		)
		const docs = await getDocs(sponsoredByQuery)
		const sponsoredBy =
			docs.docs.length > 0 && docs.docs[0].data().sponsoredBy
				? docs.docs[0].data().sponsoredBy
				: null

		if (sponsoredBy) {
			const queryServices = query(
				collection(db, "services"),
				where("serviceType", "==", jobRequest.serviceType),
				where("userId", "==", sponsoredBy)
			)
			const services = await getDocs(queryServices)
			if (services.docs.length > 0) {
				// auto respond for them
				const service = services.docs[0].data()
				const serviceRef = services.docs[0].ref
				await addDoc(collection(db, "jobRequestResponses"), {
					id: crypto.randomUUID(),
					userId: sponsoredBy,
					vendorContactEmail: service.contactEmail,
					vendorName: service.name,
					requestId: jobRequest.id,
					requestEmail: userEmail,
					available: true,
					_createdAt: new Date(),
					_updatedAt: new Date(),
					_createdBy: sponsoredBy,
					_updatedBy: sponsoredBy,
					couponCode: service.couponCode,
					...(service.couponPercent && {
						couponPercent: service.couponPercent,
					}),
					...(service.calendlyLink && { calendlyLink: service.calendlyLink }),
					...(service.couponAmount && { couponAmount: service.couponAmount }),
					serviceCharge: true,
					serviceChargeCost: 0,
					partner: "HPV",
					disclaimerText: service.disclaimerText,
				})
				await axios.post(`${config.baseURL}/api/hpv-job-request`, {
					jobRequest: jobRequestData,
					service,
					clientEmail: userEmail,
				})
				await axios.post(`${config.baseURL}/api/hpv-job-request-response`, {
					jobRequest: jobRequestData,
					service,
					clientEmail: userEmail,
				})
				await updateDoc(serviceRef, { jobResponseCount: increment(1) })
				return sponsoredBy
			}
		}

		// parse out zip
		const zipCodeMatch = jobRequest.locationAddress.match(/\b\d{5}\b/)[0]
		if (!zipCodeMatch)
			throw new Error("No zip code found: " + jobRequest.locationAddress)

		// find HPV vendor with that service and zip
		const queryServices = query(
			collection(db, "services"),
			where("serviceType", "==", jobRequest.serviceType),
			where("zipCodesServiced", "array-contains", zipCodeMatch)
		)
		const services = await getDocs(queryServices)

		if (services.docs.length > 0) {
			// check stripe sub
			const vendorEmail = services.docs[0].data().contactEmail
			const stripeCustomer = await axios.post(
				`${config.baseURL}/api/get-stripe-id`,
				{ userEmail: vendorEmail, partner: "HPV" }
			)

			const stripeCustomerId = stripeCustomer.data?.customerId

			// auto respond for them
			const service = services.docs[0].data()
			const serviceRef = services.docs[0].ref
			await addDoc(collection(db, "jobRequestResponses"), {
				id: crypto.randomUUID(),
				userId: service.userId,
				vendorContactEmail: service.contactEmail,
				vendorPhoneNumber: service.phoneNumber ? service.phoneNumber : null,
				vendorName: service.name,
				requestId: jobRequest.id,
				requestEmail: userEmail,
				calendlyLink: service.calendlyLink ? service.calendlyLink : null,
				available: true,
				_createdAt: new Date(),
				_updatedAt: new Date(),
				_createdBy: service.userId,
				_updatedBy: service.userId,
				couponCode: service.couponCode,
				...(service.couponPercent && {
					couponPercent: service.couponPercent,
				}),
				...(service.couponAmount && { couponAmount: service.couponAmount }),
				serviceCharge: true,
				serviceChargeCost: 0,
				partner: "HPV",
				jobNotes: `Thank you for submitting a job to our HomePro Valet network! ${service.name} would love the opportunity to earn your business. We offer free estimates and can meet either in person at the job site or virtually to discuss the details and provide an accurate quote.
You can schedule a time with by logging back into your HomePro Valet account and selecting 'Schedule.'
We look forward to working with you!`,
				disclaimerText: service.disclaimerText,
			})

			await axios.post(`${config.baseURL}/api/hpv-job-request`, {
				jobRequest: jobRequestData,
				service,
				clientEmail: userEmail,
			})
			await axios.post(`${config.baseURL}/api/hpv-job-request-response`, {
				jobRequest: jobRequestData,
				service,
				clientEmail: userEmail,
			})
			await updateDoc(serviceRef, { jobResponseCount: increment(1) })

			// create the invoice in stripe
			if (stripeCustomerId) {
				const leadFee = getLeadFee(jobRequest)
				await axios.post(`${config.baseURL}/api/create-hpv-invoice`, {
					userId: stripeCustomerId,
					jobRequest,
					leadFee,
				})
			}

			// return vendorId
			return service.userId
		}

		// if not subbed then send email to Chris and assign to master account
		await axios.post(
			`${config.baseURL}/api/home-pro-valet-no-vendor-available`,
			{
				jobRequest: jobRequestData,
				userEmail,
			}
		)
		return HPV_DEFAULT_VENDOR_ID
	} catch (error) {
		console.error("Error in assignJobToHPVVendor:", error)
		await axios.post(
			`${config.baseURL}/api/home-pro-valet-no-vendor-available`,
			{
				jobRequest: jobRequestData,
				userEmail,
			}
		)
		return HPV_DEFAULT_VENDOR_ID
	}
}

const getLeadFee = (jobRequest) => {
	const primaryService = HPVServicesAndFees.find(
		(service) => service.primary_service === jobRequest.serviceType
	)

	if (primaryService) {
		if (primaryService.sub_services && jobRequest.subServiceType) {
			const subService = primaryService.sub_services.find(
				(sub) => sub.name === jobRequest.subServiceType
			)
			if (subService) {
				return subService.lead_fee
			}
		}
		return primaryService.lead_fee
	}
	return null
}
