export const teamIdDefaultIds = [
	{
		teamId: "RBG",
		teamName: "Redbud Group",
		defaultId: "d09dc456-bd6d-4a3e-8911-5408a0970a3e",
		logo_path: "/assets/companyLogos/RBG_logo.svg",
	},
	{
		teamId: "SCOTTM",
		teamName: "Atlanta Communities",
		defaultId: "48a667f4-2816-4056-9486-3fcfe43bd8b3",
		logo_path: "/assets/companyLogos/ScottM_logo.svg",
	},
	{
		teamId: "LINKD",
		teamName: "Link'd Realty",
		defaultId: "bc88a0ef-4e85-47cb-b008-aa4d3738a560",
		logo_path: "/assets/companyLogos/Linkd_logo.svg",
	},
	{
		teamId: "TBRE",
		teamName: "Tom Bramhall",
		defaultId: "12fcaf16-87e7-45fb-a7ce-25d1faa1b97f",
		logo_path: "/assets/companyLogos/TBRE_logo.svg",
	},
	{
		teamId: "CAMH",
		teamName: "My Townhome",
		defaultId: "01c9affc-b36e-42f1-bc6a-1a85bee3f304",
		logo_path: "/assets/companyLogos/MyTownHome_logo.svg",
	},
	{
		teamId: "WPFS",
		teamName: "White Pickett Fence Services",
		defaultId: "22260816-1589-4fed-9a46-cf029ebb425f",
		logo_path: "/assets/companyLogos/WPFS_logo.svg",
	},
	{
		teamId: "LPTR",
		teamName: "LPT Realty",
		defaultId: "7a00f4c2-2279-4b54-b773-9a69c99930e1",
		logo_path: "/assets/companyLogos/LPTR_logo.svg",
	},
	{
		teamId: "RTRE",
		teamName: "Rooftop Realty",
		defaultId: "1ad530a9-5f42-4c65-947c-26f99cdc436f",
		logo_path: "/assets/companyLogos/RTRE_logo.svg",
	},
	{
		teamId: "RSRE",
		teamName: "Rhonda Smith Team",
		defaultId: "6fa4e1b6-efdf-4788-b2ba-ab45966fa130",
		logo_path: "/assets/companyLogos/Rhonda_Smith_logo.svg",
	},
	{
		teamId: "HPVT",
		teamName: "Home Pro Valet",
		defaultId: "f47ddcb0-1832-480d-b84d-937e3b0fc88f",
	},
	{
		teamId: "YFRE",
		teamName: "Yellowfin Realty",
		defaultId: "0b8a995e-fd74-4ea0-88c2-fd412ac0e41b",
		logo_path: "/assets/companyLogos/YFRE_logo.svg",
	},
	{
		teamId: "BDRE",
		teamName: "Black Dome Services",
		defaultId: "f89ad534-e66c-4ba7-9575-7ecfb9af007e",
		logo_path: "/assets/companyLogos/BD_logo.svg",
	},
	{
		teamId: "ABLO",
		teamName: "Atlantic Bay",
		defaultId: "436c6151-a843-4ca7-aa06-967e274c8217",
		logo_path: "/assets/companyLogos/ABLO_logo.svg",
	},
]
