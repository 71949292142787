import React, { useEffect, useState, useContext } from "react"
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import {
	AbsoluteCenter,
	Box,
	Button,
	Divider,
	FormControl,
	Heading,
	Image,
	Input,
	Link,
	VStack,
} from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext.js"
import { RegistrationCard } from "../Components/RegistrationCard"

export const Login = () => {
	const [partner, setPartner] = useState(null)
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [user, loading] = useAuthState(auth)
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const url = window.location.href
		if (url.includes("homeprovalet")) {
			setPartner("HPV")
		}
	}, [])

	useEffect(() => {
		if (
			loading ||
			!user ||
			userPlus === "pending" ||
			userPlus === null ||
			!userPlus.userType
		) {
			return
		}

		if (
			user &&
			userPlus !== "pending" &&
			userPlus !== null &&
			userPlus.userType &&
			userPlus.userType !== null
		) {
			const pathname = location.state?.from
				? location.state.from
				: userPlus.userType === "vendor"
				? "/job-requests"
				: "/vendors"

			navigate(pathname)
		}
	}, [userPlus, user, loading, location.state?.from])

	return (
		<>
			<Box
				ml={{ base: "10px", sm: "40px", md: "96px" }}
				my={{ base: "15px", md: "40px" }}
				display="flex"
				alignItems="flex-end"
			>
				<Link to="/">
					{partner === "HPV" ? (
						<Image src="/assets/HPV.svg" alt="logo" />
					) : (
						<Image src="/assets/logo_with_name.svg" alt="logo" />
					)}
				</Link>
			</Box>
			<Box display={{ md: "flex" }} alignItems={{ md: "stretch" }}>
				<Box flex={1}>
					<Heading
						as="h2"
						ml={{ base: "10px", sm: "40px", md: "96px" }}
						mr={{ base: "40px" }}
						fontSize="24px"
						mt={{ base: "15px", sm: "30px" }}
						fontWeight="600"
					>
						{" "}
						{partner
							? "Empowering every home with expert services delivered by trusted professionals"
							: "The home services exchange for real estate agents, vendors and homeowners!"}
					</Heading>
					<Heading
						as="h3"
						fontSize="16px"
						fontWeight="500"
						mt="15px"
						ml={{ base: "10px", sm: "40px", md: "96px" }}
						mr={{ base: "40px" }}
					>
						<Link
							as={NavLink}
							to={"/register"}
							color={partner === "HPV" ? "#383d93" : "borderColor"}
							textDecoration="underline"
						>
							Create your free account
						</Link>{" "}
						and start using {partner === "HPV" ? "Home Pro Valet" : "Quiplo"}.
					</Heading>
					<Box
						ml={{ base: "10px", sm: " 40px", md: "96px" }}
						mr={{ base: "10px", sm: " 40px", md: "96px" }}
						my={{ base: "15px", md: "30px" }}
					>
						<FormControl>
							<VStack gap={2} alignItems="left">
								<Input
									type="text"
									bg="white"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Email"
								/>
								<Input
									type="password"
									bg="white"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									placeholder="Password"
								/>
								<Link as={NavLink} to="/reset" fontSize="15px">
									Forgot Password?
								</Link>
								<Button
									w="100%"
									bgColor="coolGray.700"
									fontWeight="bold"
									color="white"
									border="
									coolGray.700 solid"
									onClick={() => logInWithEmailAndPassword(email, password)}
								>
									Login
								</Button>
								<Box position="relative" padding={{ base: "10px", md: "25px" }}>
									<Divider />
									<AbsoluteCenter bgColor="#fafafa" px="4">
										Or
									</AbsoluteCenter>
								</Box>
								<Button
									border="black solid"
									bgColor="white"
									w="100%"
									alignContent="left"
									onClick={signInWithGoogle}
								>
									<Image
										src="/assets/google_logo.png"
										alt="google"
										width="20px"
										height="20px"
										mr="10px"
									/>
									Continue with Google
								</Button>
							</VStack>
						</FormControl>
					</Box>
				</Box>
				<Box
					flex={1}
					style={{
						backgroundImage:
							"linear-gradient(to bottom, #fafafa 1%, rgba(245, 246, 252, 0.01)), url(/assets/stock_house_login.png)",
						backgroundSize: "cover",
						height: "100vh",
					}}
					pt={{ base: "20px", md: "0px" }}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						pr="10px"
						pl="10px"
					>
						{partner === "HPV" && (
							<>
								<RegistrationCard
									icon="/assets/HPV_clipboard_icon.svg"
									heading="Real Estate Partners"
									text="Elevate your real estate service by offering clients a seamless transition with our network of pre-screened, trusted home service professionals, making every move-in experience stree-free and exceptional."
								/>
								<RegistrationCard
									icon="/assets/HPV_todo_icon.svg"
									heading="Vendors"
									text="Join Home Pro Valet to tap into a steady stream of high-quality referrals from homeowners and real estate partners, actively seeking trusted vendors, boosting your credibility and expanding your customer base."
								/>
								<RegistrationCard
									icon="/assets/HPV_recommend_icon.svg"
									heading="Homeowners"
									text="Access a network of local home service providers who have passed our rigorous vetting process, ensuring quality and reliability every time."
								/>
							</>
						)}
						{!partner && (
							<>
								<RegistrationCard
									icon="/assets/todo_icon.svg"
									heading="Organize preferred Vendors as a Real Estate Agent"
									text="Manage all of your trusted vendors, scheduling time, and track all job requests in one place."
								/>
								<RegistrationCard
									icon="/assets/clipboard_icon.svg"
									heading="Grow your business as a skilled Vendor"
									text="Grow your business with trusted leads, repeat business, and NO lead fees."
								/>
								<RegistrationCard
									icon="/assets/recommend_icon.svg"
									heading="Share referrals with homeowners"
									text="Invite clients to have access to your skilled vendor network to stay top of mind."
								/>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}
