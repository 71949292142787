import { useEffect, useContext } from "react"

import { useNavigate } from "react-router-dom"
import { UserContext } from "../../Contexts/UserContext.js"
import { AgentProfile } from "./AgentProfile"
import { ClientProfile } from "./ClientProfile"
import { VendorProfile } from "./VendorProfile"

export const ProfileWrapper = () => {
	const navigate = useNavigate()
	const { userPlus } = useContext(UserContext)

	useEffect(() => {
		if (userPlus === "pending") {
			return
		}
		if (userPlus === null) {
			navigate("/login")
		}
	}, [userPlus])

	return (
		<>
			{userPlus?.userType === "vendor" && <VendorProfile userPlus={userPlus} />}
			{userPlus?.userType === "agent" && <AgentProfile userPlus={userPlus} />}
			{userPlus?.userType === "client" && <ClientProfile userPlus={userPlus} />}
		</>
	)
}
