import { useState } from "react"
import {
	Box,
	Button,
	Flex,
	IconButton,
	Image,
	Menu,
	MenuList,
	MenuItem,
	MenuButton,
	Text,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { useNavigate, NavLink, useMatch } from "react-router-dom"
import { logout } from "./firebase"
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons"
import { useTheme } from "./Contexts/ThemeContext"

export const VendorLoggedInNav = ({ userPlus }) => {
	const { theme } = useTheme()
	const navigate = useNavigate()
	const [display, changeDisplay] = useState("none")
	const [isOpen, setIsOpen] = useState(false)

	const handleOpen = () => {
		setIsOpen(!isOpen)
	}

	const logOutFromApp = async () => {
		try {
			await logout()
		} catch (error) {
			console.error("Failed to log out:", error)
		}
		navigate("/login")
	}

	return (
		<>
			<Box
				bgColor="white"
				borderBottom={"1px solid #cccccc"}
				height="70px"
				display={["none", "none", "flex", "flex"]}
			>
				<Flex height="100%" width="100%">
					{userPlus.partner === "HPV" ? (
						<Image
							src={`/assets/${userPlus.partner}.svg`}
							alt="logo"
							padding="18px	30px 10px 30px"
							bgColor="white"
						/>
					) : (
						<Image
							src="/assets/Logo.svg"
							alt="logo"
							padding="18px	30px 10px 30px"
							bgColor="white"
						/>
					)}

					<Flex
						width="100%"
						height="100%"
						alignItems={"center"}
						background="white"
						gap={{ base: 3, lg: 10 }}
						lineHeight="70px"
					>
						<Text
							style={{
								borderBottom: useMatch("/services")
									? `2px solid	${theme.colors.borderColor}`
									: "none",
							}}
						>
							<NavLink variant="navigationNavLink" to="/services">
								My Services
							</NavLink>
						</Text>
						<Text
							style={{
								borderBottom: useMatch("/job-requests")
									? `2px solid	${theme.colors.borderColor}`
									: "none",
							}}
						>
							<NavLink variant="navigationNavLink" to="/job-requests">
								Job Requests
							</NavLink>
						</Text>
						{!userPlus.partner && (
							<Text>
								<a
									href="https://quiploteams.com/how-it-works"
									target="_blank"
									rel="noopener noreferrer"
								>
									How it works
								</a>
							</Text>
						)}
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="flex-end"
						mr="30px"
						bgColor="white"
						width="450px"
						gap={5}
					>
						<Button as={NavLink} variant="smallCta" to="/invite">
							Invite
						</Button>

						<NavLink to="/profile">Profile</NavLink>
						<Menu>
							<MenuButton as={Text} onClick={handleOpen} w="100px">
								Account
								<ChevronDownIcon ml="3px" />
							</MenuButton>
							<MenuList>
								<MenuItem
									as="a"
									href={
										userPlus.partner
											? "https://pay.homeprovalet.com/p/login/cN2dTp2lL3UedP27ss"
											: "https://billing.stripe.com/p/login/5kA02Tg2i2QQ9rieUU"
									}
									target="_blank"
									rel="noopener noreferrer"
									w="100%"
								>
									<Text>Billing</Text>
								</MenuItem>

								<MenuItem>
									<Button onClick={logOutFromApp} w="100%">
										Log Out
									</Button>
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Flex>
			</Box>
			<Box
				bgColor="white"
				display="flex"
				width="100%"
				justifyContent="space-between"
			>
				{/* Mobile */}
				{userPlus.partner ? (
					<Image
						src={`/assets/${userPlus.partner}.svg`}
						alt="logo"
						padding="18px"
						display={["flex", "flex", "none", "none"]}
					/>
				) : (
					<Image
						src="/assets/sm_quiplo_logo.svg"
						alt="logo"
						padding="18px"
						display={["flex", "flex", "none", "none"]}
					/>
				)}
				<IconButton
					aria-label="Open Menu"
					size="xxl"
					bgColor="white"
					marginRight="18px"
					icon={<HamburgerIcon w={7} h={7} />}
					onClick={() => changeDisplay("flex")}
					display={["flex", "flex", "none", "none"]}
				/>

				{/* Mobile Content */}
				<Flex
					w="100vw"
					display={display}
					bgColor="gray.50"
					zIndex={20}
					h="100vh"
					pos="fixed"
					top="0"
					left="0"
					overflowY="auto"
					flexDir="column"
				>
					<Flex justify="flex-end">
						<IconButton
							mt={2}
							mr={2}
							aria-label="Open Menu"
							size="lg"
							bgColor="background"
							icon={<CloseIcon />}
							onClick={() => changeDisplay("none")}
						/>
					</Flex>

					<Flex flexDir="column" align="center">
						<NavLink to="/services" onClick={() => changeDisplay("none")}>
							<Button variant="ghost" aria-label="My Services" my={5} w="100%">
								My Services
							</Button>
						</NavLink>
						<NavLink to="/job-requests" onClick={() => changeDisplay("none")}>
							<Button variant="ghost" aria-label="Job Requests" my={5} w="100%">
								Job Requests
							</Button>
						</NavLink>

						{!userPlus.partner && (
							<Button
								as="a"
								variant="ghost"
								aria-label="How it works"
								href="https://quiploteams.com/how-it-works"
								onClick={() => changeDisplay("none")}
								target="_blank"
								rel="noopener noreferrer"
								my={5}
								w="100%"
							>
								How it works
							</Button>
						)}

						<NavLink to="/invite" onClick={() => changeDisplay("none")}>
							<Button variant="ghost" aria-label="Invite" my={5} w="100%">
								Invite
							</Button>
						</NavLink>

						<NavLink to="/profile" onClick={() => changeDisplay("none")}>
							<Button variant="ghost" aria-label="Profile" my={5} w="100%">
								Profile
							</Button>
						</NavLink>
						{!userPlus.partner && (
							<Button
								as="a"
								variant="ghost"
								aria-label="Billing"
								my={5}
								w="100%"
								href="https://billing.stripe.com/p/login/5kA02Tg2i2QQ9rieUU"
								target="_blank"
								rel="noopener noreferrer"
							>
								Billing
							</Button>
						)}
						<NavLink onClick={logOutFromApp}>
							<Button variant="ghost" aria-label="Log Out" my={5} w="100%">
								Log Out
							</Button>
						</NavLink>
					</Flex>
				</Flex>
			</Box>
		</>
	)
}
