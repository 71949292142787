import React, { useState, useContext } from "react"
import { Image, Tooltip } from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext"
import { Partners } from "../partnershipData"

export const VerifiedBadge = ({ id }) => {
	const { userPlus } = useContext(UserContext)
	const [isOpen, setIsOpen] = useState(false)
	const [openTooltipId, setOpenTooltipId] = useState(null)

	const partner = Partners.find((p) => p.id === userPlus?.partner)

	const onOpen = () => {
		setIsOpen(true)
		setOpenTooltipId(id)
	}

	const onClose = () => {
		setIsOpen(false)
		setOpenTooltipId(null)
	}

	const onToggle = () => {
		setIsOpen(!isOpen)
		setOpenTooltipId(isOpen ? null : id)
	}

	const shouldRenderTooltip = openTooltipId === id

	return (
		<Tooltip
			label={`Vendors who are onboarded to ${
				partner ? partner.name : "Quiplo"
			} with a commitment to promptly responding to customer requests are marked verified.`}
			fontSize="md"
			isOpen={shouldRenderTooltip && isOpen}
		>
			<Image
				src="/assets/verified_badge.svg"
				onMouseEnter={onOpen}
				onMouseLeave={onClose}
				onClick={onToggle}
				my="auto"
				ml="10px"
			/>
		</Tooltip>
	)
}
