import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { updateDoc } from "firebase/firestore"
import imageCompression from "browser-image-compression"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
	Button,
	Center,
	Heading,
	FormControl,
	FormHelperText,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Input,
	Image,
	Stack,
	Spinner,
	Switch,
	Text,
	Tooltip,
	useDisclosure,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Box,
	Flex,
	useToast,
} from "@chakra-ui/react"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import PhoneInput from "react-phone-number-input/input"
import { isValidPhoneNumber } from "react-phone-number-input"
import { addDoc, collection, query, where, getDocs } from "firebase/firestore"
import { db, logUserEvent, storage } from "../../firebase"
import { analytic_events } from "../../analytics"
import { Partners } from "../../partnershipData"

// Original AgentProfile component refactored as a tab panel component
const ProfileTabPanel = ({ userPlus, onSave }) => {
	const { isOpen, onOpen, onToggle, onClose } = useDisclosure()
	const toast = useToast()

	const [agentData, setAgentData] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		profilePicture: null,
		isHomeOwnerFlyWheelEnabled: true,
	})
	const [preview, setPreview] = useState(null)

	const [pageLoading, setPageLoading] = useState(false)
	const [formError, setFormError] = useState(false)

	const partner = Partners.find((p) => p.id === userPlus?.partner)

	const handleFileChange = async (event) => {
		const file = event.target.files[0]
		if (file) {
			try {
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 800,
					useWebWorker: true,
				}
				const compressedFile = await imageCompression(file, options)
				setAgentData({ ...agentData, profilePicture: compressedFile })

				// Generate a preview of the compressed image
				const reader = new FileReader()
				reader.onloadend = () => {
					setPreview(reader.result)
				}
				reader.readAsDataURL(compressedFile)
			} catch (error) {
				console.error("Error compressing image:", error)
			}
		}
	}

	useEffect(() => {
		if (userPlus === "pending") {
			return
		} else {
			setAgentData({
				name: userPlus.name,
				email: userPlus.email,
				phoneNumber: userPlus.phoneNumber ? userPlus.phoneNumber : null,
				profilePicture: userPlus.profilePicture
					? userPlus.profilePicture
					: null,
				isHomeOwnerFlyWheelEnabled: userPlus.isHomeOwnerFlyWheelEnabled
					? true
					: false,
			})
			setPreview(userPlus.profilePicture)
		}
	}, [userPlus])

	const verifyAgentInformation = () => {
		const hasError =
			agentData.name === "" ||
			(agentData.companyEmail === "" && agentData.phoneNumber === "") ||
			(agentData.phoneNumber &&
				agentData.phoneNumber !== "" &&
				!isValidPhoneNumber(agentData.phoneNumber))

		if (hasError) {
			alert("Please correct fields before proceeding.")
		}
		setFormError(hasError)
		return hasError
	}

	const onSubmit = async (e) => {
		let imageUrl
		e.preventDefault()
		setPageLoading(true)
		const hasError = verifyAgentInformation()
		if (hasError) {
			setPageLoading(false)
			return
		}

		if (agentData.profilePicture !== null) {
			const storageRef = ref(storage, `agentProfileImages/${userPlus.id}.jpg`)

			await uploadBytes(storageRef, agentData.profilePicture)
				.then((snapshot) => {
					return getDownloadURL(storageRef)
				})
				.then((url) => {
					imageUrl = url
				})
				.catch((error) => {
					console.error("Error uploading image or getting download URL:", error)
				})
		}

		await updateDoc(userPlus.userDocRef, {
			_updatedAt: new Date(),
			name: agentData.name,
			phoneNumber: agentData.phoneNumber,
			profilePicture: imageUrl ? imageUrl : null,
		})

		const q = query(
			collection(db, "publicAgentProfile"),
			where("id", "==", userPlus.id)
		)

		const querySnapshot = await getDocs(q)

		if (querySnapshot.docs.length === 0) {
			// No documents found, add a new document
			await addDoc(collection(db, "publicAgentProfile"), {
				id: userPlus.id,
				_updatedAt: new Date(),
				name: agentData.name,
				email: agentData.email,
				phoneNumber: agentData.phoneNumber,
				photo: imageUrl ? imageUrl : null,
				teamId: userPlus.teamId ? userPlus.teamId : null,
			})
		} else {
			// Update existing documents
			const updatePromises = querySnapshot.docs.map((doc) =>
				updateDoc(doc.ref, {
					_updatedAt: new Date(),
					name: agentData.name,
					email: agentData.email,
					phoneNumber: agentData.phoneNumber,
					photo: imageUrl ? imageUrl : null,
					teamId: userPlus.teamId ? userPlus.teamId : null,
				})
			)

			await Promise.all(updatePromises)
		}

		await logUserEvent(analytic_events.AGENT_USER_PROFILE_UPDATE, userPlus.id, {
			name: agentData.name,
		})

		if (onSave) {
			toast({
				title: "Profile saved successfully!",

				status: "success",
				duration: 5000,
				isClosable: true,
			})
			onSave()
		}
	}

	const handleChange = (input, e) => {
		setAgentData({ ...agentData, [input]: e })
	}

	const handleRemoveImage = () => {
		setAgentData({ ...agentData, profilePicture: null })
		setPreview(null)
	}

	if (pageLoading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	return (
		<Stack w="100%" gap={3}>
			<Text>
				Set up your profile to share your contact info and photo with clients
				and vendors, keeping you and your business top of mind.
			</Text>

			<FormControl isInvalid={formError && agentData.name === ""}>
				<FormLabel>Name</FormLabel>
				<Input
					bgColor="white"
					type="text"
					name="name"
					placeholder="Enter your name"
					value={agentData.name}
					onChange={(e) => {
						handleChange("name", e.target.value)
					}}
				/>
				{formError && agentData.name === "" && (
					<FormErrorMessage>Name is required.</FormErrorMessage>
				)}
			</FormControl>
			<FormControl isDisabled={userPlus.email}>
				<FormLabel>
					Email
					<Tooltip
						label={`Email can't be edited. To update it, contact us at ${
							partner ? partner.contactEmail : "contact@quiploteams.com"
						} to transfer your client insights and job history.`}
						fontSize="sm"
						isOpen={isOpen}
					>
						<IconButton
							variant="tooltip"
							height="auto"
							onMouseEnter={onOpen}
							onMouseLeave={onClose}
							onClick={onToggle}
							icon={<InfoOutlineIcon />}
						/>
					</Tooltip>
				</FormLabel>
				<Input
					bgColor="white"
					type="text"
					name="companyEmail"
					placeholder="Enter your contact email"
					value={agentData.email}
					onChange={(e) => {
						handleChange("email", e.target.value.toLowerCase())
					}}
				/>
			</FormControl>
			<FormControl
				isInvalid={
					formError &&
					(agentData.phoneNumber === "" ||
						(agentData.phoneNumber &&
							!isValidPhoneNumber(agentData.phoneNumber)))
				}
			>
				<FormLabel>Phone Number</FormLabel>
				<PhoneInput
					country="US"
					style={{
						width: "100%",
						height: "40px",
						padding: "0.5rem 0.75rem",
						border:
							formError &&
							(agentData.phoneNumber === "" ||
								(agentData.phoneNumber &&
									!isValidPhoneNumber(agentData.phoneNumber)))
								? "1px solid #F05252"
								: "1px solid #CBD5E0",
						borderRadius: "0.375rem",
						outlineColor:
							formError &&
							(agentData.phoneNumber === "" ||
								(agentData.phoneNumber &&
									!isValidPhoneNumber(agentData.phoneNumber)))
								? "#F05252"
								: "#CBD5E0",
					}}
					placeholder="Enter phone number"
					value={agentData.phoneNumber}
					onChange={(e) => {
						setAgentData((prevagentData) => ({
							...prevagentData,
							phoneNumber: e,
						}))
					}}
					onFocus={(e) => {
						e.target.style.borderColor = "#4299e1"
						e.target.style.boxShadow = "0 0 0 3px rgba(66, 153, 225, 0.1)"
						e.target.style.outlineColor = "#4299e1"
					}}
					onBlur={(e) => {
						e.target.style.borderColor =
							formError &&
							(agentData.phoneNumber === "" ||
								(agentData.phoneNumber &&
									!isValidPhoneNumber(agentData.phoneNumber)))
								? "#F05252"
								: "#CBD5E0"
						e.target.style.boxShadow =
							formError &&
							(agentData.phoneNumber === "" ||
								(agentData.phoneNumber &&
									!isValidPhoneNumber(agentData.phoneNumber)))
								? "0 0 0 1px #F05252"
								: "none"
					}}
				/>
				{formError &&
					(agentData.phoneNumber === "" ||
						(agentData.phoneNumber &&
							!isValidPhoneNumber(agentData.phoneNumber))) && (
						<FormErrorMessage>
							Please enter a valid phone number.
						</FormErrorMessage>
					)}
			</FormControl>

			<FormControl>
				<FormLabel>Profile Picture</FormLabel>
				<Input
					bgColor="white"
					py="1"
					type="file"
					accept="image/jpeg"
					onChange={handleFileChange}
				/>
				<FormHelperText>Upload a profile picture in jpeg format</FormHelperText>
				{preview && (
					<>
						<Image
							src={preview}
							alt="Profile Preview"
							mt={4}
							w="300px"
							h="300px"
						/>
						<Button mt={2} colorScheme="red" onClick={handleRemoveImage}>
							Remove
						</Button>
					</>
				)}
			</FormControl>

			<Button
				w="100%"
				bgColor="borderColor"
				color="white"
				onClick={onSubmit}
				mt="30px"
				mb="40px"
			>
				Save
			</Button>
		</Stack>
	)
}

// New Settings and Notifications tab panel
const SettingsNotificationsTabPanel = ({ userPlus }) => {
	const toast = useToast()
	const [settings, setSettings] = useState({
		isHomeOwnerFlyWheelEnabled: userPlus.isHomeOwnerFlyWheelEnabled
			? true
			: false,
	})

	const handleToggle = (setting) => {
		setSettings({
			...settings,
			[setting]: !settings[setting],
		})
	}

	const saveSettings = async () => {
		try {
			// Update user settings in Firestore
			await updateDoc(userPlus.userDocRef, {
				_updatedAt: new Date(),
				isHomeOwnerFlyWheelEnabled: settings.isHomeOwnerFlyWheelEnabled,
			})
			return toast({
				title: "Settings saved successfully!",

				status: "success",
				duration: 5000,
				isClosable: true,
			})
		} catch (error) {
			console.error("Error saving settings:", error)
			alert("Failed to save settings. Please try again.")
		}
	}

	useEffect(() => {
		// Load settings from userPlus if available
		if (userPlus && userPlus !== "pending" && userPlus.settings) {
			setSettings(userPlus.settings)
		}
	}, [userPlus])

	return (
		<Stack w="100%" gap={4} mt={2}>
			<Text>Manage your notification preferences and account settings.</Text>

			{/* <Box bg="white" p={5} borderRadius="md" shadow="sm">
				<Heading size="md" mb={4}>
					Notifications
				</Heading>

				<Stack spacing={4}>
					<Flex justify="space-between" align="center">
						<Box>
							<Text fontWeight="medium">Email Notifications</Text>
							<Text fontSize="sm" color="gray.600">
								Receive updates, reminders, and client messages via email
							</Text>
						</Box>
						<Switch
							isChecked={settings.emailNotifications}
							onChange={() => handleToggle("emailNotifications")}
							colorScheme="blue"
						/>
					</Flex>

					<Divider />

					<Flex justify="space-between" align="center">
						<Box>
							<Text fontWeight="medium">SMS Notifications</Text>
							<Text fontSize="sm" color="gray.600">
								Receive text messages for urgent updates and client inquiries
							</Text>
						</Box>
						<Switch
							isChecked={settings.smsNotifications}
							onChange={() => handleToggle("smsNotifications")}
							colorScheme="blue"
						/>
					</Flex>
				</Stack>
			</Box> */}

			<Box bg="white" p={5} borderRadius="md" shadow="sm">
				<Heading size="md" mb={4}>
					Application Settings
				</Heading>

				<Stack spacing={4}>
					<Flex justify="space-between" align="center">
						<Box>
							<Text fontWeight="medium">Homeowner Referrals</Text>
							<Text fontSize="sm" color="gray.600">
								Enable your clients to share your vendor list with friends and
								family for homeowner lead generation.
							</Text>
						</Box>
						<Switch
							isChecked={settings.isHomeOwnerFlyWheelEnabled}
							onChange={() => handleToggle("isHomeOwnerFlyWheelEnabled")}
							colorScheme="blue"
						/>
					</Flex>
				</Stack>
			</Box>

			<Button
				w="100%"
				bgColor="borderColor"
				color="white"
				onClick={saveSettings}
				mt="20px"
				mb="40px"
			>
				Save Settings
			</Button>
		</Stack>
	)
}

// Main tabbed component that wraps everything
export const AgentProfile = ({ userPlus }) => {
	const navigate = useNavigate()
	const [tabIndex, setTabIndex] = useState(0)

	const handleTabsChange = (index) => {
		setTabIndex(index)
	}

	if (userPlus === "pending") {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	return (
		<Center
			flexDirection="column"
			maxW="629px"
			margin={{ base: "15px", md: "auto" }}
		>
			<Heading
				size="lg"
				fontWeight="bold"
				mt="30px"
				mb="6"
				alignSelf="flex-start"
			>
				Your Account
			</Heading>

			<Tabs
				isFitted
				variant="enclosed"
				w="100%"
				index={tabIndex}
				onChange={handleTabsChange}
			>
				<TabList mb="1em">
					<Tab
						fontWeight="medium"
						border="none"
						_selected={{ color: "borderColor" }}
					>
						Profile
					</Tab>
					<Tab
						fontWeight="medium"
						border="none"
						_selected={{ color: "borderColor" }}
					>
						Settings & Notifications
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<ProfileTabPanel
							userPlus={userPlus}
							onSave={() => navigate("/vendors")}
						/>
					</TabPanel>
					<TabPanel>
						<SettingsNotificationsTabPanel userPlus={userPlus} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Center>
	)
}
