import React, { useContext, useEffect, useState, useRef } from "react"
import { Select } from "chakra-react-select"
import { NavLink, useNavigate } from "react-router-dom"
import axios from "axios"
import {
	collection,
	addDoc,
	query,
	where,
	getDocs,
	getDoc,
	doc,
	increment,
	updateDoc,
} from "firebase/firestore"
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Box,
	Button,
	HStack,
	Stack,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Image,
	IconButton,
	Link,
	Text,
} from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import PhoneInput from "react-phone-number-input/input"
import { teamIdDefaultIds } from "../Options/DefaultTeamIds"
import { isValidPhoneNumber } from "react-phone-number-input"
import { db, logUserEvent } from "../firebase"
import { serviceTypesOptions } from "../Options/ServiceTypes"
import { CharlotteZipCodes } from "../Options/CharlotteZipCodes"
import { UserContext } from "../Contexts/UserContext"
import { analytic_events } from "../analytics"
import config from "../config.js"
import {
	NorthCarolinaLocationOptions,
	SouthCarolinaLocationOptions,
	GeorgiaLocationOptions,
	FloridaLocationOptions,
	TexasLocationOptions,
} from "../Options/Locations"
import "react-phone-number-input/style.css"

export const VendorInviteForm = ({
	nextStep,
	previousStep,
	editingIndex,
	tcTeam,
	tcAgent,
	teamMembersSelected,
	vendorList,
	vendorData,
	setEditingIndex,
	sendInviteEmail,
	setTcTeam,
	setVendorData,
	setVendorList,
	setTeamMembersSelected,
}) => {
	const inputRef = useRef()
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const [formError, setFormError] = useState(false)
	const [teamMembersSelect, setTeamMembersSelect] = useState([])
	const [agentInformation, setAgentInformation] = useState([])

	const getNameById = (array, id) => {
		const foundObject = array.find((obj) => obj.id === id)
		return foundObject ? foundObject.name : null
	}

	const getEmailById = (array, id) => {
		const foundObject = array.find((obj) => obj.id === id)
		return foundObject ? foundObject.email : null
	}

	const serviceStateOptions = [
		{ label: "Georgia", value: "GA" },
		{ label: "North Carolina", value: "NC" },
		{ label: "South Carolina", value: "SC" },
		{ label: "Flordia", value: "FL" },
		{ label: "Texas", value: "TX" },
	]

	let serviceCityOptions = []

	vendorData.serviceStates.forEach((state) => {
		switch (state) {
			case "GA":
				serviceCityOptions.push(GeorgiaLocationOptions)
				break
			case "NC":
				serviceCityOptions.push(NorthCarolinaLocationOptions)
				break
			case "SC":
				serviceCityOptions.push(SouthCarolinaLocationOptions)
				break
			case "FL":
				serviceCityOptions.push(FloridaLocationOptions)
				break
			case "TX":
				serviceCityOptions.push(TexasLocationOptions)
				break
			default:
				break
		}
	})

	// Flatten the array of arrays into a single array
	serviceCityOptions = [].concat(...serviceCityOptions)

	useEffect(() => {
		if (!tcTeam) {
			return
		} else {
			const getAgentsInfo = async () => {
				try {
					// Query users with the specified teamId
					const q = query(
						collection(db, "users"),
						where("teamId", "==", tcTeam),
						where("userType", "==", "agent")
					)
					const doc = await getDocs(q)

					const teamMembersSelect = doc.docs.map((doc) => {
						return {
							label: doc.get("email"),
							value: doc.get("id"),
						}
					})

					setAgentInformation(
						doc.docs.map((doc) => {
							return {
								email: doc.get("email"),
								id: doc.get("id"),
								name: doc.get("name"),
							}
						})
					)

					setTeamMembersSelect(teamMembersSelect)
				} catch (error) {
					console.error("Error fetching user emails:", error)
				}
			}
			getAgentsInfo()
		}
	}, [tcTeam])

	useEffect(() => {
		if (userPlus.partner === "HPV" && userPlus.userType === "vendor") {
			setVendorData({
				...vendorData,
				serviceStates: ["NC"],
				serviceCities: ["Charlotte, NC"],
			})
		}
	}, [userPlus.partner])

	const teamSelectOptions =
		userPlus?.userType === "TC" &&
		userPlus?.tcTeams?.map((team) => {
			const teamName = teamIdDefaultIds.find(
				(teamDefault) => teamDefault.teamId === team
			)
			return { value: team, label: teamName.teamName || team }
		})

	const handleChange = (input, e) => {
		setVendorData({ ...vendorData, [input]: e })
	}
	const handleServiceTypeChange = (selectedOptions, fieldName) => {
		setVendorData({
			...vendorData,
			[fieldName]: selectedOptions.map((option) => option.value),
		})
	}

	const verifyVendorInformation = () => {
		const fields = ["name", "serviceTypes", "serviceStates", "serviceCities"]
		const isAnyFieldEmpty = fields.some(
			(field) =>
				vendorData[field] === "" ||
				(Array.isArray(vendorData[field]) && vendorData[field].length === 0)
		)
		const hasError =
			isAnyFieldEmpty ||
			vendorData.serviceTypes.length > 2 ||
			(vendorData.companyEmail === "" && vendorData.phoneNumber === "") ||
			(vendorData.phoneNumber !== "" &&
				!isValidPhoneNumber(vendorData.phoneNumber))

		if (hasError) {
			alert("Please correct fields before proceeding.")
		}
		setFormError(hasError)
		return hasError
	}

	const addAdditionalVendor = (e) => {
		e.preventDefault()
		const hasError = verifyVendorInformation()
		if (hasError) {
			return new Error("Form error")
		}
		if (editingIndex !== null) {
			const newVendorList = [...vendorList]
			newVendorList[editingIndex] = {
				...vendorData,
				teamId: tcTeam,
				tcAgentId: teamMembersSelected,
				tcAgentName: getNameById(agentInformation, teamMembersSelected),
				tcAgentEmail: getEmailById(agentInformation, teamMembersSelected),
			}
			setVendorList(newVendorList)
			setVendorData({
				name: "",
				companyEmail: "",
				phoneNumber: "",
				serviceTypes: [],
				serviceCities: [],
				serviceStates: [],
				zipCodesServiced: [],
			})
			setTcTeam(null)
			setTeamMembersSelect([])
			setTeamMembersSelected([])
			setEditingIndex(null)
			nextStep()
		} else {
			setVendorList([
				...vendorList,
				{
					...vendorData,
					teamId: tcTeam,
					tcAgentId: teamMembersSelected,
					tcAgentName: getNameById(agentInformation, teamMembersSelected),
					tcAgentEmail: getEmailById(agentInformation, teamMembersSelected),
				},
			])
			setVendorData({
				name: "",
				companyEmail: "",
				phoneNumber: "",
				serviceTypes: [],
				serviceCities: [],
				serviceStates: [],
				zipCodesServiced: [],
			})
			setTcTeam(null)
			setTeamMembersSelect([])
			setTeamMembersSelected([])
		}
	}

	const onSubmitVendor = async (e) => {
		try {
			e.preventDefault()
			const hasError = verifyVendorInformation()
			if (hasError) {
				return new Error("Form error")
			}
			const emailQuery = query(
				collection(db, "users"),
				where("email", "==", vendorData.companyEmail)
			)

			const phoneQuery = query(
				collection(db, "users"),
				where("phoneNumber", "==", vendorData.phoneNumber)
			)

			const emailDocs = await getDocs(emailQuery)
			const phoneDocs = await getDocs(phoneQuery)

			const userExists =
				(vendorData.companyEmail !== "" && emailDocs.docs.length > 0) ||
				(vendorData.phoneNumber !== "" && phoneDocs.docs.length > 0)
			const doc1 =
				emailDocs.docs.length > 0
					? emailDocs.docs[0]?.data()
					: phoneDocs.docs[0]?.data()
					? phoneDocs.docs[0]?.data()
					: null

			let invitedUserIsAdmin = false
			if (userExists) {
				let invitedUserIsAdmin = doc1.isAdmin
				const existingUserId = doc1.id
				const relationshipQuery = query(
					collection(db, "agentVendor"),
					where(
						"agentId",
						"==",
						teamMembersSelected ? teamMembersSelected : userPlus.id
					),
					where("vendorId", "==", existingUserId)
				)

				const association = await getDocs(relationshipQuery)
				const isUserAlreadyAssociated = association.docs.length > 0

				if (userPlus.userType === "TC" && !isUserAlreadyAssociated) {
					await addDoc(collection(db, "agentVendor"), {
						id: crypto.randomUUID(),
						agentId: teamMembersSelected ? teamMembersSelected : userPlus.id,
						vendorId: existingUserId,
						teamId: tcTeam,
					})
				}

				if (!isUserAlreadyAssociated && userPlus.userType === "agent") {
					await addDoc(collection(db, "agentVendor"), {
						id: crypto.randomUUID(),
						vendorId: existingUserId,
						agentId: userPlus.id,
						...((userPlus.isAdmin || invitedUserIsAdmin) && {
							teamId: userPlus.teamId,
						}),
					})
				}
			} else {
				const userDoc = await addDoc(collection(db, "users"), {
					id: crypto.randomUUID(),
					email:
						vendorData.companyEmail !== ""
							? vendorData.companyEmail.toLowerCase()
							: null,
					name: vendorData.name,
					phoneNumber:
						vendorData.phoneNumber !== "" ? vendorData.phoneNumber : null,
					invitedBy: userPlus.id,
					teamId: null,
					userType: "vendor",
					...(userPlus.partner === "HPV" &&
						userPlus.isAdmin &&
						userPlus.userType === "vendor" && { partner: "HPV" }),
					numberOfServices: 0,
					_createdAt: new Date(),
				})

				const docRef = doc(db, "users", userDoc.id)
				const newUser = await getDoc(docRef)
				const userId = newUser.data().id

				if (userPlus.userType === "agent") {
					await addDoc(collection(db, "agentVendor"), {
						id: crypto.randomUUID(),
						agentId: userPlus.id,
						vendorId: userId,
						...((userPlus.isAdmin || invitedUserIsAdmin) && {
							teamId: userPlus.teamId,
						}),
					})
				}
				if (userPlus.userType === "TC") {
					await addDoc(collection(db, "agentVendor"), {
						id: crypto.randomUUID(),
						agentId: teamMembersSelected ? teamMembersSelected : userPlus.id,
						vendorId: userId,
						teamId: tcTeam,
					})
				}

				await Promise.all(
					vendorData.serviceTypes.map(async (serviceType) => {
						const id = crypto.randomUUID()
						await addDoc(collection(db, "services"), {
							id,
							userId: userId,
							_createdAt: new Date(),
							_updatedAt: new Date(),
							_createdBy: userPlus.id,
							_updatedBy: userPlus.id,
							serviceType,
							contactEmail:
								vendorData.companyEmail !== ""
									? vendorData.companyEmail.toLowerCase()
									: null,
							phoneNumber:
								vendorData.phoneNumber !== "" ? vendorData.phoneNumber : null,
							name: vendorData.name,
							stateLocation: vendorData.serviceStates,
							selectLocations: vendorData.serviceCities,
							...(userPlus.partner === "HPV" &&
								userPlus.isAdmin && { partner: "HPV" }),
							...(userPlus.partner === "HPV" &&
								userPlus.isAdmin && {
									zipCodesServiced: vendorData.zipCodesServiced,
								}),
						})
						await addDoc(collection(db, "vendorServiceMap"), {
							_createdAt: new Date(),
							vendorId: userId,
							serviceId: id,
							...(userPlus.partner === "HPV" &&
								userPlus.isAdmin && { partner: "HPV" }),
						})
						if (teamMembersSelected) {
							await addDoc(collection(db, "userPreferredServices"), {
								_createdAt: new Date(),
								userId: teamMembersSelected,
								serviceId: id,
								teamId: tcTeam ? tcTeam : null,
								vendorId: userId,
							})
						}
					})
				)

				await updateDoc(doc(db, "users", docRef.id), {
					numberOfServices: increment(vendorData.serviceTypes.length),
				})

				await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
					email: vendorData.companyEmail,
					inviteType: "vendor",
					invitedBy: userPlus.id,
				})
			}
			if (vendorData.companyEmail) {
				sendVendorInviteEmail()
			}
			if (vendorData.phoneNumber) {
				sendVendorInviteText(userPlus.name, vendorData)
			}
			navigate(userPlus.userType === "vendor" ? "/services" : "/vendors")
		} catch (error) {
			console.error("Error adding user and associating:", error)
		}
	}

	const sendVendorInviteEmail = async () => {
		if (userPlus.partner === "HPV" && !userPlus.isAdmin) {
			return await axios.post(
				`${config.baseURL}/api/invite-independent-vendor-to-homeProValet`,
				{
					vendorEmail: vendorData.companyEmail,
					serviceType: vendorData.serviceTypes.join(", "),
					name: vendorData.name,
					userEmail: teamMembersSelected
						? getEmailById(agentInformation, teamMembersSelected)
						: userPlus.email,
					userName: teamMembersSelected
						? getNameById(agentInformation, teamMembersSelected)
						: userPlus.name,
				}
			)
		}
		if (
			userPlus.partner === "HPV" &&
			userPlus.isAdmin &&
			userPlus.userType === "vendor"
		) {
			return await axios.post(
				`${config.baseURL}/api/invite-vendor-to-homeprovalet`,
				{
					vendorEmail: vendorData.companyEmail,
					serviceType: vendorData.serviceTypes.join(", "),
					name: vendorData.name,
					userEmail: teamMembersSelected
						? getEmailById(agentInformation, teamMembersSelected)
						: userPlus.email,
					userName: teamMembersSelected
						? getNameById(agentInformation, teamMembersSelected)
						: userPlus.name,
				}
			)
		} else {
			await axios.post(`${config.baseURL}/api/invite-vendor-to-quiplo`, {
				vendorEmail: vendorData.companyEmail,
				serviceType: vendorData.serviceTypes.join(", "),
				name: vendorData.name,
				userEmail: teamMembersSelected
					? getEmailById(agentInformation, teamMembersSelected)
					: userPlus.email,
				userName: teamMembersSelected
					? getNameById(agentInformation, teamMembersSelected)
					: userPlus.name,
			})
		}
	}

	const sendVendorInviteText = async () => {
		await axios.post(`${config.baseURL}/api/sms-invite-user`, {
			partner: userPlus.partner,
			isHPVMaster: userPlus.isAdmin && userPlus.userType === "vendor",
			phoneNumber: vendorData.phoneNumber,
			serviceType: vendorData.serviceTypes.join(", "),
			agentName: teamMembersSelected
				? getNameById(agentInformation, teamMembersSelected)
				: userPlus.name,
		})
	}

	const handleReview = () => {
		setVendorList([
			...vendorList,
			{
				...vendorData,
				teamId: tcTeam,
				tcAgentId: teamMembersSelected,
				tcAgentName: getNameById(agentInformation, teamMembersSelected),
				tcAgentEmail: getEmailById(agentInformation, teamMembersSelected),
			},
		])
		setVendorData({
			name: "",
			companyEmail: "",
			phoneNumber: "",
			serviceTypes: [],
		})
		setTcTeam(null)
		setTeamMembersSelect([])
		setTeamMembersSelected([])
		nextStep()
	}

	return (
		<Stack w="100%" gap={3}>
			<HStack my="15px" gap={0}>
				<IconButton
					aria-label="Return to Vendors"
					size="md"
					bgColor="background"
					icon={<ArrowBackIcon />}
					onClick={() => previousStep()}
					_hover={{ color: "borderColor" }}
				/>
				<Link as={NavLink} variant="secondary" to="/vendors">
					Back
				</Link>
			</HStack>
			{userPlus.userType === "TC" && (
				<>
					<FormControl>
						<FormLabel display="flex">
							Select the team you are adding the vendor to
						</FormLabel>
						<Select
							placeholder="Select team"
							options={teamSelectOptions}
							value={
								tcTeam
									? teamSelectOptions.find((option) => tcTeam === option.value)
									: null
							}
							onChange={(e) => setTcTeam(e.value)}
						/>
					</FormControl>
					<FormControl>
						<FormLabel display="flex">
							Select agent to add vendor to their list
						</FormLabel>
						<Select
							isDisabled={!tcTeam}
							placeholder="Select agent"
							options={teamMembersSelect}
							value={
								setTeamMembersSelected
									? teamMembersSelect.find((option) => tcTeam === option.value)
									: null
							}
							onChange={(e) => setTeamMembersSelected(e.value)}
						/>
					</FormControl>
				</>
			)}
			<FormControl isInvalid={formError && vendorData.name === ""}>
				<FormLabel>Company Name</FormLabel>
				<Input
					bgColor="white"
					type="text"
					name="name"
					placeholder="Enter company name"
					value={vendorData.name}
					onChange={(e) => {
						handleChange("name", e.target.value)
					}}
				/>
				{formError && vendorData.name === "" && (
					<FormErrorMessage>Company Name is required.</FormErrorMessage>
				)}
			</FormControl>
			<FormControl
				isInvalid={
					formError &&
					(vendorData.serviceTypes.length === 0 ||
						vendorData.serviceTypes.length > 2)
				}
			>
				<FormLabel>Service Types</FormLabel>
				<Select
					isMulti
					options={serviceTypesOptions}
					value={serviceTypesOptions.filter((option) =>
						vendorData.serviceTypes.includes(option.value)
					)}
					onChange={(e) => handleServiceTypeChange(e, "serviceTypes")}
				/>

				<FormErrorMessage>
					{vendorData.serviceTypes.length === 0
						? "A service is required."
						: vendorData.serviceTypes.length > 2
						? "When inviting a vendor, you can only select 2 services. Encourage vendors to create an account and add more services. You and your team can always send a vendor a job request even if you do not see their service in your vendor list."
						: null}
				</FormErrorMessage>
			</FormControl>
			<FormControl
				isInvalid={formError && vendorData.serviceStates.length === 0}
			>
				<FormLabel>Select States</FormLabel>
				<Select
					isMulti
					options={serviceStateOptions}
					value={serviceStateOptions.filter((option) =>
						vendorData.serviceStates.includes(option.value)
					)}
					onChange={(e) => handleServiceTypeChange(e, "serviceStates")}
				/>

				<FormErrorMessage>
					{formError &&
						vendorData.serviceTypes.length === 0 &&
						"A service state is required."}
				</FormErrorMessage>
			</FormControl>
			<FormControl
				isInvalid={formError && vendorData.serviceCities.length === 0}
			>
				<FormLabel>Select Cities</FormLabel>
				<Select
					isMulti
					options={serviceCityOptions}
					value={serviceCityOptions.filter((option) =>
						vendorData.serviceCities.includes(option.value)
					)}
					onChange={(e) => handleServiceTypeChange(e, "serviceCities")}
				/>

				<FormErrorMessage>
					{formError &&
						vendorData.serviceCities.length === 0 &&
						"A service city is required."}
				</FormErrorMessage>
			</FormControl>
			{userPlus.partner === "HPV" &&
				userPlus.isAdmin &&
				userPlus.userType === "vendor" &&
				vendorData.serviceCities.length > 0 &&
				vendorData.serviceCities.includes("Charlotte, NC") && (
					<FormControl
						isInvalid={
							formError &&
							vendorData.selectLocations?.length === 0 &&
							vendorData.zipCodesServiced?.length === 0
						}
					>
						<FormLabel> Select Zip Codes Serviced</FormLabel>
						<Select
							isMulti
							isDisabled={vendorData.serviceStates === null}
							options={CharlotteZipCodes}
							value={CharlotteZipCodes.filter((option) =>
								vendorData.zipCodesServiced.includes(option.value)
							)}
							onChange={(e) => handleServiceTypeChange(e, "zipCodesServiced")}
							placeholder="Select Zip Codes"
						/>
					</FormControl>
				)}
			<Accordion allowToggle mt="20px">
				<AccordionItem bgColor="white">
					<h2>
						<AccordionButton>
							<Box display="flex" flex="1" textAlign="left">
								<Image src="/assets/chat.svg" />
								<Text my="auto" ml="10px">
									Message
								</Text>
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						<FormControl
							isInvalid={
								formError &&
								vendorData.phoneNumber !== null &&
								!isValidPhoneNumber(vendorData.phoneNumber)
							}
						>
							<PhoneInput
								country="US"
								style={{
									width: "100%",
									height: "40px",
									padding: "0.5rem 0.75rem",
									border: "1px solid #CBD5E0",
									outlineColor: "#CBD5E0",
									borderRadius: "0.375rem",
								}}
								placeholder="Enter company phone number"
								value={vendorData.phoneNumber}
								onChange={(e) => {
									handleChange("phoneNumber", e)
								}}
								onFocus={(e) => {
									e.target.style.borderColor = "#4299e1"
									e.target.style.boxShadow = "0 0 0 3px rgba(66, 153, 225, 0.1)"
									e.target.style.outlineColor = "#4299e1 !important"
								}}
								onBlur={(e) => {
									e.target.style.borderColor =
										formError && !isValidPhoneNumber(vendorData.phoneNumber)
											? "#F05252"
											: "#CBD5E0"
									e.target.style.boxShadow =
										formError && !isValidPhoneNumber(vendorData.phoneNumber)
											? "0 0 0 1px #F05252"
											: "none"
								}}
							/>
							{formError && (
								<FormErrorMessage>
									Company Phone Number is Invalid.
								</FormErrorMessage>
							)}
						</FormControl>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem bgColor="white">
					<h2>
						<AccordionButton>
							<Box display="flex" flex="1" textAlign="left">
								<Image src="/assets/mail.svg" />
								<Text my="auto" ml="10px">
									Email
								</Text>
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						<FormControl
							isInvalid={formError && vendorData.companyEmail === ""}
						>
							<Input
								bgColor="white"
								type="text"
								name="companyEmail"
								placeholder="Enter company contact email"
								value={vendorData.companyEmail}
								onChange={(e) => {
									handleChange("companyEmail", e.target.value)
								}}
							/>
							{formError && vendorData.companyEmail === "" && (
								<FormErrorMessage>Company Email is required.</FormErrorMessage>
							)}
						</FormControl>
					</AccordionPanel>
				</AccordionItem>
				{userPlus.userType === "agent" && !userPlus.partner && (
					<AccordionItem bgColor="white">
						<h2>
							<AccordionButton>
								<Box display="flex" flex="1" textAlign="left">
									<Image src="/assets/link.svg" />
									<Text my="auto" ml="10px">
										Share Invite Link
									</Text>
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel>
							<FormControl mb="10px">
								<Flex>
									<Input
										bgColor="white"
										ref={inputRef}
										value={`${config.baseURL}/register/${userPlus.id}`}
										readOnly
									/>
									<Button
										ml="10px"
										bgColor="black"
										color="white"
										onClick={() => {
											navigator.clipboard.writeText(inputRef.current.value)
										}}
									>
										Copy
									</Button>
								</Flex>
							</FormControl>
						</AccordionPanel>
					</AccordionItem>
				)}
			</Accordion>
			{editingIndex !== null && (
				<Button
					onClick={(e) => addAdditionalVendor(e)}
					w="100%"
					mt="10px"
					mb="40px"
				>
					Edit Service Request
				</Button>
			)}
			{editingIndex === null && (
				<Button
					type="button"
					onClick={(e) => addAdditionalVendor(e)}
					w="100%"
					bgColor="borderColor"
					color="white"
					mt="40px"
				>
					{userPlus.partner ? "Add Another Home Pro" : "Add Another Vendor"}
				</Button>
			)}
			{vendorList.length > 0 && editingIndex === null && (
				<Button
					onClick={handleReview}
					w="100%"
					bgColor="borderColor"
					color="white"
					mt="20px"
				>
					Review Invites
				</Button>
			)}
			{vendorList.length === 0 && editingIndex === null && (
				<Button
					onClick={(e) => onSubmitVendor(e)}
					w="100%"
					bgColor="borderColor"
					color="white"
					mt="20px"
					mb="40px"
				>
					Send Invite
				</Button>
			)}
		</Stack>
	)
}
