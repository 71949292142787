import React, { useState, useContext } from "react"
import {
	Button,
	Center,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Heading,
	IconButton,
	Input,
	Stack,
	Text,
	Tooltip,
	useDisclosure,
} from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { serviceTypesOptions } from "../Options/ServiceTypes"
import {
	NorthCarolinaLocationOptions,
	SouthCarolinaLocationOptions,
	GeorgiaLocationOptions,
	FloridaLocationOptions,
	TexasLocationOptions,
} from "../Options/Locations"
import { MultiDisplayInput } from "./MultiDisplayInput"
import { UserContext } from "../Contexts/UserContext"
import { Partners } from "../partnershipData"
import "../select.css"

export const JobResponseCreateServiceForm = ({
	serviceType,
	nextStep,
	jobRequest,
	formError,
}) => {
	const { userPlus } = useContext(UserContext)
	const { isOpen, onOpen, onToggle, onClose } = useDisclosure()
	const [stateSelection, setStateSelection] = useState([])
	const [serviceData, setServiceData] = useState({
		name: userPlus.name,
		contactEmail: userPlus.email,
		serviceType: jobRequest.serviceType,
		description: "",
		selectLocations: [],
		zipCodesServiced: [],
	})

	const partner = Partners.find((p) => p.id === userPlus?.partner)

	const stateOptions = [
		{ label: "Georgia", value: "GA" },
		{ label: "North Carolina", value: "NC" },
		{ label: "South Carolina", value: "SC" },
		{ label: "Flordia", value: "FL" },
		{ label: "Texas", value: "TX" },
	]
	let serviceCityOptions = []

	stateSelection?.forEach((state) => {
		switch (state) {
			case "GA":
				serviceCityOptions.push(GeorgiaLocationOptions)
				break
			case "NC":
				serviceCityOptions.push(NorthCarolinaLocationOptions)
				break
			case "SC":
				serviceCityOptions.push(SouthCarolinaLocationOptions)
				break
			case "FL":
				serviceCityOptions.push(FloridaLocationOptions)
				break
			case "TX":
				serviceCityOptions.push(TexasLocationOptions)
				break
			default:
				break
		}
	})

	// Flatten the array of arrays into a single array
	serviceCityOptions = [].concat(...serviceCityOptions)

	const handleGenericSelectChange = (selectedOptions, fieldName) => {
		setServiceData({
			...serviceData,
			[fieldName]: selectedOptions.map((option) => option.value),
		})
	}

	const handleChange = (input, e) => {
		setServiceData({ ...serviceData, [input]: e })
	}

	return (
		<>
			<Center maxW="585px" margin={{ base: "15px", md: "auto" }}>
				<Stack gap={5} mt={{ base: "20px", md: "40px" }}>
					<Heading size="lg" fontWeight="bold">
						Add {jobRequest.serviceType} Service
					</Heading>
					<Text mt="10px">
						To respond and receive future {serviceType} job requests, let’s
						first add this to your services.
					</Text>

					<FormControl>
						<FormLabel>Company Name</FormLabel>
						<Input
							isDisabled
							bgColor="white"
							type="text"
							name="name"
							placeholder="Enter your company name"
							value={serviceData.name}
							onChange={(e) => {
								handleChange("name", e.target.value)
							}}
						/>
						{formError && serviceData.name === "" ? (
							<FormErrorMessage>Company name required.</FormErrorMessage>
						) : null}
					</FormControl>
					<FormControl>
						<FormLabel>
							Contact Email
							<Tooltip
								label={`Your contact email is your account email. If you would like to change this please contact ${
									partner ? partner.name : "Quiplo"
								} ${
									partner ? partner.contactEmail : "contact@quiploteams.com"
								}`}
								fontSize="sm"
								isOpen={isOpen}
							>
								<IconButton
									variant="tooltip"
									height="auto"
									onMouseEnter={onOpen}
									onMouseLeave={onClose}
									onClick={onToggle}
									icon={<InfoOutlineIcon />}
								/>
							</Tooltip>
						</FormLabel>
						<Input
							isDisabled
							bgColor="white"
							type="email"
							name="contactEmail"
							placeholder="Enter email"
							value={serviceData.contactEmail}
							onChange={(e) => {
								handleChange("contactEmail", e.target.value)
							}}
						/>
					</FormControl>
					<FormControl isDisabled>
						<FormLabel>Service Type</FormLabel>
						<Select
							name="serviceType"
							options={serviceTypesOptions}
							value={serviceTypesOptions.find(
								(option) => serviceData.serviceType === option.value
							)}
							onChange={(e) => handleGenericSelectChange(e, "serviceType")}
						/>
					</FormControl>

					<FormControl>
						<FormLabel>Description of Service</FormLabel>
						<Input
							bgColor="white"
							type="text"
							name="description"
							value={serviceData.description}
							onChange={(e) => {
								handleChange("description", e.target.value)
							}}
							placeholder="Optional"
						/>
					</FormControl>
					<FormControl isInvalid={formError && stateSelection?.length === 0}>
						<FormLabel> Select State</FormLabel>
						<Select
							isMulti
							options={stateOptions}
							value={stateOptions.filter((option) =>
								stateSelection?.includes(option.value)
							)}
							onChange={(e) =>
								setStateSelection(e.map((option) => option.value))
							}
							placeholder="Select State"
						/>
						{formError && stateSelection?.length === 0 ? (
							<FormErrorMessage>A state is required.</FormErrorMessage>
						) : null}
					</FormControl>
					<FormControl
						isInvalid={formError && serviceData.selectLocations.length === 0}
					>
						<FormLabel> Select Locations</FormLabel>
						<Select
							isMulti
							options={serviceCityOptions}
							value={serviceCityOptions.filter((option) =>
								serviceData.selectLocations.includes(option.value)
							)}
							onChange={(e) => handleGenericSelectChange(e, "selectLocations")}
							placeholder="Select Locations"
						/>
						{formError && serviceData.selectLocations.length === 0 ? (
							<FormErrorMessage>A location is required.</FormErrorMessage>
						) : null}
					</FormControl>
					<FormControl
						isInvalid={formError && serviceData.zipCodesServiced.length === 0}
					>
						<FormLabel>Or enter zip codes you service</FormLabel>
						<MultiDisplayInput
							name="zipCodesServiced"
							placeholder="Enter zip codes"
							formData={serviceData.zipCodesServiced}
							handleFormChange={(e) => handleChange("zipCodesServiced", e)}
							isZip={true}
							// isInvalid={formError && serviceData.zipCodesServiced.length === 0}
						/>
						<FormHelperText mb="5px">
							Enter zip codes followed by enter or paste a batch of them
							separated by commas.
						</FormHelperText>
						{formError && serviceData.zipCodesServiced.length === 0 ? (
							<FormErrorMessage>A location is required.</FormErrorMessage>
						) : null}
					</FormControl>

					<Button
						onClick={() => nextStep(serviceData, stateSelection)}
						w="100%"
						bgColor="borderColor"
						color="white"
						mt="30px"
						mb={{ base: "20px", lg: "60px" }}
					>
						Respond to Job Request
					</Button>
				</Stack>
			</Center>
		</>
	)
}
