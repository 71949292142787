import React from "react"
import { Image } from "@chakra-ui/react"

export const ServiceIcon = ({ serviceType }) => {
	const services = {
		"Air Duct Cleaning": "Clean",
		"Appliance Installation": "Appliance",
		"Appliance Repair": "Appliance",
		"Asbestos Removal": "Truck",
		Awnings: "House",
		"Basement Remodeling": "Tool",
		"Bathroom Remodel": "Bath",
		"Bathtub Refinishing": "Bath",
		"Biohazard Cleanup": "Truck",
		"Bi-monthly Lawn Cuts": "Leaf",
		"Boat Rentals": "Pool",
		"Cabinet Replacement & Refacing": "Kitchen",
		Carpentry: "Measure",
		"Carpet Cleaning": "Clean",
		"Carpet Installation": "Floor",
		"Chimney Cap Repair": "House",
		"Chimney Inspection": "Inspect",
		"Chimney Repair": "Tool",
		"Chimney Sweep": "Clean",
		"Christmas Lights": "House",
		"Closet Design": "Measure",
		Closings: "Finance",
		"Commercial Cleaning": "Clean",
		"Concrete Driveways": "House",
		"Concrete Repair": "House",
		"Countertop Installation": "Floor",
		"Crawl Space Encapsulation": "Tool",
		"Crawl Space Inspection": "Inspect",
		"Crawl Space Repair": "Tool",
		"Credit Repair": "Finance",
		"Deck Staining & Cleaning": "Paint",
		"Decks & Porches Install & Repair": "Tool",
		Demolition: "Truck",
		"Drain Cleaning": "Plumbing",
		"Drain Pipe Installation & Repair": "Tool",
		"Driveway Gate Installation": "Tool",
		"Driveway Pavers": "Tool",
		"Dryer Vent Cleaning": "Clean",
		"Drywall Installation & Repair": "Tool",
		"Dumpster Rental": "Truck",
		"Electrical Inspection": "Electric",
		Electrician: "Inspect",
		"Epoxy Flooring": "Electric",
		Excavating: "House",
		"Exterior Painting": "Paint",
		Fencing: "Tool",
		"Floor Buffing": "Floor",
		"Floor Cleaning": "Clean",
		"Foundation Inspection": "Inspect",
		"Foundation Repair": "House",
		"Furniture Refinishing": "Paint",
		"Garage Building": "House",
		"Garage Door Installation and Repair": "House",
		"Gas Leak Repair": "Tool",
		"General Contractor": "Tool",
		"General Inspection": "Inspect",
		"General Plumbing": "Plumbing",
		"Gutter Cleaning": "Leaf",
		"Gutter Installation": "House",
		"Gutter Repair": "House",
		Handyman: "Tool",
		Hardscaping: "Tree",
		"Hardwood Floor Installation & Repair": "Floor",
		"Home Remodeling": "Kitchen",
		"Home Staging": "Camera",
		"Hot Water Heater Installation & Repair": "Appliance",
		"House Cleaning": "Clean",
		"HVAC Inspection": "Inspect",
		"HVAC Installation & Repair": "Tool",
		"Insulation Install": "Tool",
		"Interior Painting": "Paint",
		"Interior Design": "House",
		"Irrigation System": "Tree",
		"Irrigation Inspection": "Inspect",
		"Kitchen Remodel": "Kitchen",
		"Landscape Design": "Tree",
		"Landscape Lighting": "Tree",
		"Lawn Care": "Leaf",
		"Lawn Treatment": "Leaf",
		"Lead Paint Removal": "Truck",
		"Lead-Based Paint Inspection": "Inspect",
		"Leaf Removal": "Leaf",
		Locksmith: "Lock",
		Masonry: "House",
		Matterport: "Camera",
		Measurements: "Measure",
		"Mold Inspection": "Inspect",
		"Mold Remediation": "House",
		"Move In Concierge": "Truck",
		"Monthly Lawn Cuts": "Leaf",
		Mudjacking: "House",
		"Mulch Delivering": "Leaf",
		Nurseries: "Tree",
		"Outdoor Drainage": "Tree",
		"Outdoor Kitchens": "House",
		"Patio Enclosures": "House",
		Patios: "House",
		"Pest Control": "Bug",
		"Pest Inspection": "Inspect",
		Plastering: "House",
		"Plumbing Inspection": "Inspect",
		"Pool & Spa Inspection": "Inspect",
		"Pool Cleaning": "Pool",
		"Pool Installers": "Pool",
		"Popcorn Ceiling Removal": "House",
		"Pressure Washing": "House",
		"Professional Organizers": "Measure",
		"Property Managers": "Finance",
		"Property Survey": "Finance",
		"Radon Inspection": "Inspect",
		"Roof Cleaning": "House",
		"Roof Inspection": "Inspect",
		"Re-Keying": "Lock",
		"General Roofing": "House",
		"Screen Enclosures": "House",
		"Structural Inspection": "Inspect",
		"Stucco Inspection": "Inspect",
		"Septic & Well Repair": "House",
		"Shower Glass": "Bath",
		"Siding Repair & Installation": "House",
		"Soil Inspection": "Inspect",
		"Solar Panel Installation": "House",
		"Stamped Concrete": "House",
		"Standard Photography": "Camera",
		"Stone & Gravel": "Leaf",
		"Structural Engineering": "Tool",
		Stucco: "House",
		"Tile Installation": "Tile",
		Title: "Finance",
		"Termite Inspection": "Bug",
		"Trash Removal": "Truck",
		"Tree Service": "Tree",
		Videographer: "Camera",
		"Wallpaper Hanger": "Paint",
		"Wallpaper Removal": "Paint",
		"Water Damage Restoration": "Bath",
		"Well & Septic Inspection": "Inspect",
		"Window Cleaning": "House",
		"Window Replacement": "House",
		"Window Treatment": "House",
		"Weekly Lawn Cuts": "Leaf",
		"Mortgage Lender": "Finance",
		"Closing Attorney": "Finance",
		Appraiser: "Finance",
		"Moving Services": "Truck",
		"Termite Control": "Bug",
		"Floor Installation & Repair": "Floor",
		"Oil Tank Inspection": "Inspect",
		"Oil Inspection (2)": "Inspect",
		"Marketing Services": "Camera",
		"Homeowner Insurance": "Finance",
		"Home Warranty": "Finance",
	}

	// Get the appropriate SVG component based on serviceType
	const selectedIcon = services[serviceType]

	return (
		<Image
			src={`/assets/servicesIcons/${selectedIcon}.svg`}
			w="40px"
			h="40px"
		/>
	)
}
