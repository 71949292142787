import { Box, Text } from "@chakra-ui/react"
import { useContext } from "react"
import { UserContext } from "./Contexts/UserContext"
import { Partners } from "./partnershipData.js"

export const Footer = () => {
	const { userPlus } = useContext(UserContext)

	const partner = Partners.find((p) => p.id === userPlus?.partner)

	return (
		<Box
			w="100%"
			bg="white"
			color="coolGray.600"
			py={10}
			textAlign="right"
			fontSize="sm"
			pr={{ base: "10px", lg: "80px" }}
		>
			{userPlus?.partner ? (
				<>
					<Text>{partner.footerCopy.line1}</Text>
					<Text>{partner.footerCopy.line2}</Text>
					<Text>{partner.footerCopy.line3}</Text>
				</>
			) : (
				<>
					<Text>© 2025 Relay Scheduling LLC dba Quiplo.</Text>
					<Text>All Rights Reserved.</Text>
					<Text>For questions, email contact@quiploteams.com.</Text>
				</>
			)}
		</Box>
	)
}
