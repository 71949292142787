export const CharlotteZipCodes = [
	{ value: "28202", label: "28202", city: "Central Charlotte, NC" },
	{ value: "28203", label: "28203", city: "Central Charlotte, NC" },
	{ value: "28204", label: "28204", city: "Central Charlotte, NC" },
	{ value: "28205", label: "28205", city: "East Charlotte, NC" },
	{ value: "28206", label: "28206", city: "North Charlotte, NC" },
	{ value: "28207", label: "28207", city: "South Charlotte, NC" },
	{ value: "28208", label: "28208", city: "West Charlotte, NC" },
	{ value: "28209", label: "28209", city: "Central Charlotte, NC" },
	{ value: "28210", label: "28210", city: "South Charlotte, NC" },
	{ value: "28211", label: "28211", city: "South Charlotte, NC" },
	{ value: "28212", label: "28212", city: "East Charlotte, NC" },
	{ value: "28213", label: "28213", city: "East Charlotte, NC" },
	{ value: "28214", label: "28214", city: "West Charlotte, NC" },
	{ value: "28215", label: "28215", city: "East Charlotte, NC" },
	{ value: "28216", label: "28216", city: "North Charlotte, NC" },
	{ value: "28217", label: "28217", city: "West Charlotte, NC" },
	{ value: "28226", label: "28226", city: "South Charlotte, NC" },
	{ value: "28227", label: "28227", city: "East Charlotte, NC" },
	{ value: "28244", label: "28244", city: "Central Charlotte, NC" },
	{ value: "28254", label: "28254", city: "West Charlotte, NC" },
	{ value: "28262", label: "28262", city: "North Charlotte, NC" },
	{ value: "28269", label: "28269", city: "North Charlotte, NC" },
	{ value: "28270", label: "28270", city: "South Charlotte, NC" },
	{ value: "28273", label: "28273", city: "West Charlotte, NC" },
	{ value: "28277", label: "28277", city: "South Charlotte, NC" },
	{ value: "28278", label: "28278", city: "West Charlotte, NC" },
	{ value: "28280", label: "28280", city: "Central Charlotte, NC" },
	{ value: "28281", label: "28281", city: "Central Charlotte, NC" },
	{ value: "28282", label: "28282", city: "Central Charlotte, NC" },
	{ value: "28284", label: "28284", city: "Central Charlotte, NC" },
	{ value: "28285", label: "28285", city: "Central Charlotte, NC" },
	{ value: "28287", label: "28287", city: "South Charlotte, NC" },
	{ value: "29710", label: "29710", city: "Clover, SC" },
	{ value: "29708", label: "29708", city: "Tega Cay, SC" },
	{ value: "29715", label: "29715", city: "Fort Mill, SC" },
	{ value: "29716", label: "29716", city: "Fort Mill, SC" },
	{ value: "29717", label: "29717", city: "Hickory Grove, SC" },
	{ value: "29726", label: "29726", city: "McConnells, SC" },
	{ value: "29730", label: "29730", city: "Rock Hill, SC" },
	{ value: "29731", label: "29731", city: "Rock Hill, SC" },
	{ value: "29732", label: "29732", city: "Rock Hill, SC" },
	{ value: "29733", label: "29733", city: "Rock Hill, SC" },
	{ value: "29734", label: "29734", city: "Rock Hill, SC" },
	{ value: "29742", label: "29742", city: "Sharon, SC" },
	{ value: "29743", label: "29743", city: "Smyrna, SC" },
	{ value: "29745", label: "29745", city: "York, SC" },
	{ value: "29720", label: "29720", city: "Lancaster, SC" },
	{ value: "29721", label: "29721", city: "Lancaster, SC" },
	{ value: "29722", label: "29722", city: "Lancaster, SC" },
	{ value: "29744", label: "29744", city: "Van Wyck, SC" },
	{ value: "28079", label: "28079", city: "Indian Trail, NC" },
	{ value: "28107", label: "28107", city: "JAARS, NC" },
	{ value: "28103", label: "28103", city: "Marshville, NC" },
	{ value: "28108", label: "28108", city: "Mineral Springs, NC" },
	{ value: "28110", label: "28110", city: "Monroe, NC" },
	{ value: "28111", label: "28111", city: "Monroe, NC" },
	{ value: "28112", label: "28112", city: "Monroe, NC" },
	{ value: "28104", label: "28104", city: "Weddington, NC" },
	{ value: "28173", label: "28173", city: "Weddington, NC" },
	{ value: "28174", label: "28174", city: "Wingate, NC" },
	{ value: "28012", label: "28012", city: "Belmont, NC" },
	{ value: "28016", label: "28016", city: "Bessemer City, NC" },
	{ value: "28021", label: "28021", city: "Cherryville, NC" },
	{ value: "28032", label: "28032", city: "Cramerton, NC" },
	{ value: "28034", label: "28034", city: "Dallas, NC" },
	{ value: "28052", label: "28052", city: "Gastonia, NC" },
	{ value: "28054", label: "28054", city: "Gastonia, NC" },
	{ value: "28056", label: "28056", city: "Gastonia, NC" },
	{ value: "28077", label: "28077", city: "High Shoals, NC" },
	{ value: "28098", label: "28098", city: "Lowell, NC" },
	{ value: "28101", label: "28101", city: "McAdenville, NC" },
	{ value: "28120", label: "28120", city: "Mount Holly, NC" },
	{ value: "28036", label: "28036", city: "Davidson, NC" },
	{ value: "28078", label: "28078", city: "Huntersville, NC" },
	{ value: "28105", label: "28105", city: "Matthews, NC" },
	{ value: "28227", label: "28227", city: "Mint Hill, NC" },
	{ value: "28134", label: "28134", city: "Pineville, NC" },
]

export const CharlotteMetroAreas = [
	"Central Charlotte, NC",
	"East Charlotte, NC",
	"North Charlotte, NC",
	"South Charlotte, NC",
	"West Charlotte, NC",
	"Clover, SC",
	"Tega Cay, SC",
	"Fort Mill, SC",
	"Hickory Grove, SC",
	"McConnells, SC",
	"Rock Hill, SC",
	"Sharon, SC",
	"Smyrna, SC",
	"York, SC",
	"Lancaster, SC",
	"Van Wyck, SC",
	"Indian Trail, NC",
	"JAARS, NC",
	"Marshville, NC",
	"Mineral Springs, NC",
	"Monroe, NC",
	"Weddington, NC",
	"Wingate, NC",
	"Belmont, NC",
	"Bessemer City, NC",
	"Cherryville, NC",
	"Cramerton, NC",
	"Dallas, NC",
	"Gastonia, NC",
	"High Shoals, NC",
	"Lowell, NC",
	"McAdenville, NC",
	"Mount Holly, NC",
	"Davidson, NC",
	"Huntersville, NC",
	"Matthews, NC",
	"Mint Hill, NC",
	"Pineville, NC",
]
