import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { useLocation, useNavigate, NavLink } from "react-router-dom"
import {
	Box,
	Button,
	Center,
	FormLabel,
	FormControl,
	FormHelperText,
	FormErrorMessage,
	Heading,
	HStack,
	Input,
	IconButton,
	InputLeftElement,
	InputGroup,
	Link,
	Stack,
	RadioGroup,
	Radio,
	Text,
	Tooltip,
} from "@chakra-ui/react"
import { ArrowBackIcon, InfoOutlineIcon } from "@chakra-ui/icons"
import { Select } from "chakra-react-select"
import {
	addDoc,
	collection,
	updateDoc,
	doc,
	query,
	where,
	getDocs,
	getDoc,
	increment,
} from "firebase/firestore"
import { db, logUserEvent } from "../../firebase"
import { serviceTypesOptions } from "../../Options/ServiceTypes"
import {
	NorthCarolinaLocationOptions,
	SouthCarolinaLocationOptions,
	GeorgiaLocationOptions,
	FloridaLocationOptions,
	TexasLocationOptions,
} from "../../Options/Locations"
import {
	CharlotteZipCodes,
	CharlotteMetroAreas,
} from "../../Options/CharlotteZipCodes"
import { MultiDisplayInput } from "../../Components/MultiDisplayInput"
import { analytic_events } from "../../analytics"
import { UserContext } from "../../Contexts/UserContext"
import { PaywallBanner } from "../../Components/PaywallBanner"
import { VendorPayWallModal } from "../../Components/VendorPayWall"
import { HPVServicesAndFees } from "../../Options/HPVServicesAndFees"
import { checkVendorSubscriptionServiceLimit } from "../../Utilities.js"
import { Partners } from "../../partnershipData"
import { ReactMultiEmail } from "react-multi-email"
import "react-multi-email/dist/style.css"
import "../../email-input.css"
import config from "../../config.js"
import "../../select.css"

export const ServiceForm = () => {
	const location = useLocation()
	const service = location.state?.service || null
	const [serviceDocRef, setServiceDocRef] = useState(null)
	const navigate = useNavigate()
	const { userPlus } = useContext(UserContext)
	const [formError, setFormError] = useState(false)
	const [inviteEmails, setInviteEmails] = useState([])
	const [serviceTypes, setServiceTypes] = useState(
		service ? [service.serviceType] : []
	)
	const [paywallTriggered, setPaywallTriggered] = useState(false)
	const [modal, setModal] = useState(false)
	const [tooltip1, setTooltip1Visible] = useState(false)
	const [tooltip2, setTooltip2Visible] = useState(false)
	const [focused, setFocused] = useState(false)
	const [hpvCompanyOptions, setHpvCompanyOptions] = useState([])
	const [hpvVendors, setHpvVendors] = useState([])
	const [stateSelection, setStateSelection] = useState(
		service && service.stateLocation ? service.stateLocation : []
	)
	const [serviceData, setServiceData] = useState({
		name: "",
		contactEmail: "",
		contactPhoneNumber: "",
		calendlyLink: "",
		serviceType: null,
		description: "",
		selectLocations: [],
		zipCodesServiced: [],
		couponCode: userPlus.partner === "HPV" ? "percent" : null,
		couponAmount: null,
		couponPercent: userPlus.partner === "HPV" ? 10 : null,
		disclaimerText: null,
		...service,
	})

	const partner = Partners.find((p) => p.id === userPlus?.partner)

	const stateOptions = [
		{ label: "Georgia", value: "GA" },
		{ label: "North Carolina", value: "NC" },
		{ label: "South Carolina", value: "SC" },
		{ label: "Florida", value: "FL" },
		{ label: "Texas", value: "TX" },
	]
	let serviceCityOptions = []

	stateSelection?.forEach((state) => {
		switch (state) {
			case "GA":
				serviceCityOptions.push(GeorgiaLocationOptions)
				break
			case "NC":
				serviceCityOptions.push(NorthCarolinaLocationOptions)
				break
			case "SC":
				serviceCityOptions.push(SouthCarolinaLocationOptions)
				break
			case "FL":
				serviceCityOptions.push(FloridaLocationOptions)
				break
			case "TX":
				serviceCityOptions.push(TexasLocationOptions)
				break
			default:
				break
		}
	})

	// Flatten the array of arrays into a single array
	serviceCityOptions = [].concat(...serviceCityOptions)

	const HPVPrimaryServiceOptions = HPVServicesAndFees.map((s) => {
		return { label: s.primary_service, value: s.primary_service }
	})

	useEffect(() => {
		if (!userPlus || userPlus === "pending") {
			return
		}

		const getHpvVendors = async () => {
			const q = query(
				collection(db, "users"),
				where("partner", "==", "HPV"),
				where("userType", "==", "vendor")
			)
			const docs = await getDocs(q)
			if (docs.docs.length > 0) {
				setHpvCompanyOptions(
					docs.docs.map((doc) => ({
						label: doc.data().name,
						value: doc.data().id,
					}))
				)
				setHpvVendors(
					docs.docs.map((doc) => ({
						...doc.data(),
					}))
				)
			}
		}

		setServiceData({
			name: userPlus.name,
			contactEmail: userPlus.email,
			contactPhoneNumber: userPlus.phoneNumber,
			serviceType: null,
			description: "",
			selectLocations: [],
			zipCodesServiced: [],
			...service,
		})
		if (userPlus.partner && userPlus.partner === "HPV") {
			setServiceData({
				name: null,
				contactEmail: null,
				contactPhoneNumber: null,
				serviceType: null,
				description: "",
				selectLocations: [],
				zipCodesServiced: [],
				...service,
			})
			setStateSelection(["NC"])
			setServiceData({ ...serviceData })
			getHpvVendors()
		}
	}, [userPlus])

	useEffect(() => {
		const fetchService = async () => {
			if (service) {
				const querySnapshot = await getDocs(
					query(collection(db, "services"), where("id", "==", service.id))
				)
				querySnapshot.forEach((doc) => {
					setServiceDocRef(doc.ref.id)
				})
			}
		}
		fetchService()
	}, [service])

	useEffect(() => {
		const selectedCities = serviceData.selectLocations.filter((location) =>
			CharlotteMetroAreas.includes(location)
		)

		if (selectedCities.length > 0) {
			const zips = CharlotteZipCodes.filter((zip) =>
				selectedCities.includes(zip.city)
			).map((zip) => zip.value)

			const uniqueZips = [...new Set(zips)]

			setServiceData((prev) => ({ ...prev, zipCodesServiced: uniqueZips }))
		} else {
			setServiceData((prev) => ({ ...prev, zipCodesServiced: [] }))
		}
	}, [serviceData.selectLocations])

	const handleGenericSelectChange = (selectedOptions, fieldName) => {
		setServiceData({
			...serviceData,
			[fieldName]: selectedOptions.map((option) => option.value),
		})
	}

	const handleHpvVendorChange = (selectedOption) => {
		const vendor = hpvVendors.find((vendor) => vendor.id === selectedOption)

		setServiceData({
			...serviceData,
			name: vendor.name,
			contactEmail: vendor.email,
			contactPhoneNumber: vendor.phoneNumber,
			calendlyLink: vendor.calendlyLink ? vendor.calendlyLink : "",
			hpvVendorId: vendor.id,
		})
	}

	const handleServiceTypeChange = (selectedOptions, fieldName) => {
		setServiceTypes([...selectedOptions.map((option) => option.value)])
	}

	const handleChange = (input, e) => {
		setServiceData({ ...serviceData, [input]: e })
	}

	const serviceTypeOptionList =
		userPlus.partner === "HPV" ? HPVPrimaryServiceOptions : serviceTypesOptions

	const locationMissing =
		(serviceData.selectLocations?.length === 0 &&
			serviceData.zipCodesServiced?.length === 0) ||
		stateSelection.length === 0

	const handleSubmit = async (e) => {
		e.preventDefault()
		const fields = ["name", "serviceType"]

		const isAnyFieldEmpty = fields.some(
			(field) =>
				serviceData[field] === "" ||
				(Array.isArray(serviceData[field]) && serviceData[field].length === 0)
		)

		if (
			!userPlus.partner &&
			checkVendorSubscriptionServiceLimit(userPlus, serviceTypes.length) ===
				false &&
			!service
		) {
			setFormError(true)
			setPaywallTriggered(true)
			setModal(true)
			return alert("Please upgrade your plan to add more services")
		}

		if (userPlus.partner === "HPV" && serviceData.couponPercent < 10) {
			alert("Please add a minimum of 10% coupon")
			setFormError(true)
			return
		}

		if (isAnyFieldEmpty || locationMissing) {
			alert("Please fill in all fields before proceeding.")
			setFormError(true)
			return
		} else {
			if (service) {
				await updateDoc(doc(db, "services", serviceDocRef), {
					...serviceData,
					stateLocation: stateSelection,
					_updatedAt: new Date(),
					_updatedBy: userPlus.id,
					contactEmail:
						serviceData.contactEmail === "" ? null : serviceData.contactEmail,
				})
				if (userPlus.partner === "HPV") {
					await updateDoc(userPlus.userDocRef, {
						...(userPlus.partner === "HPV" && {
							calendlyLink: serviceData.calendlyLink,
						}),
					})
				}
			} else {
				await Promise.all(
					serviceTypes.map(async (serviceType) => {
						const id = crypto.randomUUID()
						// Add a new document to the 'services' collection with the service data
						await addDoc(collection(db, "services"), {
							id,
							userId: serviceData.hpvVendorId
								? serviceData.hpvVendorId
								: userPlus.id,
							_createdAt: new Date(),
							_updatedAt: new Date(),
							_createdBy: userPlus.id,
							_updatedBy: userPlus.id,
							...serviceData,
							serviceType: serviceType,
							stateLocation: stateSelection,
							subscriptionStatusActive: userPlus.activePlan,
							contactEmail:
								serviceData.contactEmail === ""
									? null
									: serviceData.contactEmail,
							googleRating: userPlus.googleRating
								? userPlus.googleRating
								: null,
							googleReviewCount: userPlus.googleReviewCount
								? userPlus.googleReviewCount
								: null,
							googlePlaceId: userPlus.googlePlaceId
								? userPlus.googlePlaceId
								: null,
							...(userPlus.partner === "HPV" && { partner: "HPV" }),
						})
						await addDoc(collection(db, "vendorServiceMap"), {
							_createdAt: new Date(),
							vendorId: serviceData.hpvVendorId
								? serviceData.hpvVendorId
								: userPlus.id,
							serviceId: id,
							...(userPlus.partner === "HPV" && { partner: "HPV" }),
						})
						await logUserEvent(analytic_events.SERVICE_CREATED, userPlus.id, {
							serviceData: JSON.stringify(serviceData),
						})
					})
				)
				if (!service) {
					await updateDoc(userPlus.userDocRef, {
						numberOfServices: increment(serviceTypes.length),
						...(userPlus.partner === "HPV" && {
							calendlyLink: serviceData.calendlyLink,
						}),
					})
				}
				if (userPlus.partner === "HPV") {
					await updateDoc(userPlus.userDocRef, {
						...(userPlus.partner === "HPV" && {
							calendlyLink: serviceData.calendlyLink,
						}),
					})
				}
			}

			await Promise.all(
				inviteEmails.map(async (inviteEmail) => {
					const invitedQuery = query(
						collection(db, "users"),
						where("email", "==", inviteEmail)
					)
					const doc1 = await getDocs(invitedQuery)
					const userExists = doc1.docs.length > 0
					let existingUserId
					let isExistingAdmin
					let exisitngTeamId
					if (userExists) {
						existingUserId = doc1.docs[0].data().id
						isExistingAdmin = doc1.docs[0].data().isAdmin
						exisitngTeamId = doc1.docs[0].data().teamId
						const relationshipQuery = query(
							collection(db, "agentVendor"),
							where("vendorId", "==", userPlus.id),
							where("agentId", "==", existingUserId)
						)
						const association = await getDocs(relationshipQuery)
						const isUserAlreadyAssociated = association.docs.length > 0
						if (!isUserAlreadyAssociated && userPlus.partner !== "HPV") {
							try {
								await addDoc(collection(db, "agentVendor"), {
									id: crypto.randomUUID(),
									vendorId: userPlus.id,
									agentId: existingUserId,
									...(isExistingAdmin && { teamId: exisitngTeamId }),
								})
							} catch (error) {
								console.error("Error adding user and associating:", error)
							}
						}
					} else {
						try {
							const userDoc = await addDoc(collection(db, "users"), {
								id: crypto.randomUUID(),
								email: inviteEmail.toLowerCase(),
								invitedBy: userPlus.id,
								userType: "agent",
								teamId: null,
								jobRequestsMade: 0,
								_createdAt: new Date(),
								...(userPlus.partner && { partner: userPlus.partner }),
								...(userPlus.partner === "HPV" &&
									!userPlus.isAdmin && { sponsoredBy: userPlus.id }),
								isHomeOwnerFlyWheelEnabled: true,
							})

							await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
								email: inviteEmail.toLowerCase(),
								inviteType: "agent",
								invitedBy: userPlus.id,
							})
							if (userPlus.partner !== "HPV") {
								const docRef = doc(db, "users", userDoc.id)
								const newUser = await getDoc(docRef)
								const userId = newUser.data().id
								await addDoc(collection(db, "agentVendor"), {
									id: crypto.randomUUID(),
									vendorId: userPlus.id,
									agentId: userId,
									teamId: null,
								})
							}
						} catch (error) {
							console.error("Error adding user and associating:", error)
						}
					}
					try {
						await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
							email: inviteEmail,
							inviteType: "agent",
							invitedBy: userPlus.id,
						})
					} catch (error) {
						console.error("Error adding user and associating:", error)
					}
				})
			)
			sendInviteEmail(userPlus.name, inviteEmails, userPlus.email)
			navigate("/services")
		}
	}

	const sendInviteEmail = async (userName, inviteEmails, userEmail) => {
		await Promise.all(
			inviteEmails.map(async (inviteeEmail) => {
				try {
					await axios.post(`${config.baseURL}/api/invite-to-quiplo`, {
						inviteeEmail,
						userEmail,
						userName,
					})
				} catch (error) {
					console.error("Error sending email:", error)
				}
			})
		)
	}

	return (
		<>
			{paywallTriggered && <PaywallBanner location="vendorNewServiceForm" />}
			<Center maxW="585px" margin={{ base: "15px", md: "auto" }}>
				<Box
					ml={{ base: "15px", lg: "93px" }}
					mr={{ base: "15px", md: "0px" }}
					mt="30px"
				>
					<Heading size="lg" fontWeight="bold">
						Create a New Service
					</Heading>
					<Text mt="10px">
						Add your business services so agents, teams and property managers
						can share job requests.
					</Text>
					<HStack my="15px" gap={0}>
						<IconButton
							aria-label="Return to Vendors"
							size="md"
							bgColor="background"
							icon={<ArrowBackIcon />}
							onClick={() => navigate("/services")}
							_hover={{ color: "borderColor" }}
						/>
						<Link as={NavLink} variant="secondary" to="/services">
							Services
						</Link>
					</HStack>
					<Center
						flexDirection="column"
						maxW="629px"
						margin={{ base: "15px", md: "auto" }}
						gap={3}
					>
						{userPlus.partner === "HPV" && userPlus.isAdmin && (
							<FormControl
								isInvalid={
									formError && stateSelection?.length === 0 && !paywallTriggered
								}
							>
								<FormLabel> Select Company</FormLabel>
								<Select
									options={hpvCompanyOptions}
									value={hpvCompanyOptions.find(
										(option) => serviceData.name === option.label
									)}
									onChange={(e) => handleHpvVendorChange(e.value)}
									placeholder="Select Company"
								/>
							</FormControl>
						)}
						<FormControl>
							<FormLabel>Company Name</FormLabel>
							<Input
								isDisabled
								bgColor="white"
								type="text"
								name="name"
								placeholder="Enter your company name"
								value={serviceData.name}
								onChange={(e) => {
									handleChange("name", e.target.value)
								}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>
								Contact Email
								<Tooltip
									label={`Your contact email is your account email. If you would like to change this please contact ${
										partner ? partner.name : "Quiplo"
									} ${
										partner ? partner.contactEmail : "contact@quiploteams.com"
									}`}
									fontSize="sm"
									isOpen={tooltip1}
								>
									<IconButton
										variant="tooltip"
										height="auto"
										onClick={() => setTooltip1Visible(!tooltip1)}
										icon={<InfoOutlineIcon />}
									/>
								</Tooltip>
							</FormLabel>
							<Input
								isDisabled
								bgColor="white"
								type="email"
								name="contactEmail"
								placeholder="Enter email"
								value={serviceData.contactEmail}
								onChange={(e) => {
									handleChange("contactEmail", e.target.value)
								}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>
								Contact Phone Number
								<Tooltip
									label="Add your phone number to receive text alerts for new job leads."
									fontSize="sm"
									isOpen={tooltip2}
								>
									<IconButton
										variant="tooltip"
										height="auto"
										onClick={() => setTooltip2Visible(!tooltip2)}
										icon={<InfoOutlineIcon />}
									/>
								</Tooltip>
							</FormLabel>
							<Input
								isDisabled
								bgColor="white"
								type="tel"
								name="contactPhoneNumber"
								placeholder="Enter phone number"
								value={serviceData.contactPhoneNumber}
								onChange={(e) => {
									handleChange("contactPhoneNumber", e.target.value)
								}}
							/>
						</FormControl>
						{userPlus.partner && userPlus.isAdmin && (
							<FormControl>
								<FormLabel>Calendly Link</FormLabel>
								<Input
									bgColor="white"
									type="text"
									name="calendlyLink"
									value={serviceData.calendlyLink}
									onChange={(e) => {
										handleChange("calendlyLink", e.target.value)
									}}
									placeholder="Calendly Link"
								/>
							</FormControl>
						)}
						<FormControl
							isInvalid={formError && serviceTypes.length === 0}
							isDisabled={service}
						>
							<FormLabel>Service Types</FormLabel>
							<Select
								isMulti
								options={serviceTypeOptionList}
								value={serviceTypeOptionList.filter((option) =>
									serviceTypes.includes(option.value)
								)}
								onChange={(e) => handleServiceTypeChange(e, "serviceTypes")}
							/>
							{formError && serviceTypes.length === 0 ? (
								<FormErrorMessage>A service type is required.</FormErrorMessage>
							) : null}
						</FormControl>

						<FormControl>
							<FormLabel>Description of Service</FormLabel>
							<Input
								bgColor="white"
								type="text"
								name="description"
								value={serviceData.description}
								onChange={(e) => {
									handleChange("description", e.target.value)
								}}
								placeholder="Optional"
							/>
						</FormControl>
						{(!userPlus?.partner || (userPlus.partner && userPlus.isAdmin)) && (
							<>
								<FormControl
									isInvalid={
										formError &&
										stateSelection?.length === 0 &&
										!paywallTriggered
									}
								>
									<FormLabel> Select State</FormLabel>
									<Select
										isMulti
										options={stateOptions}
										value={stateOptions.filter((option) =>
											stateSelection.includes(option.value)
										)}
										onChange={(e) =>
											setStateSelection(e.map((option) => option.value))
										}
										placeholder="Select State"
									/>
									{formError &&
									stateSelection?.length === 0 &&
									!paywallTriggered ? (
										<FormErrorMessage>A state is required.</FormErrorMessage>
									) : null}
								</FormControl>
								<FormControl
									isInvalid={
										formError &&
										serviceData.selectLocations?.length === 0 &&
										serviceData.zipCodesServiced?.length === 0 &&
										!paywallTriggered
									}
								>
									<FormLabel> Select Locations</FormLabel>
									<Select
										isMulti
										isDisabled={stateSelection === null}
										options={serviceCityOptions}
										value={serviceCityOptions.filter((option) =>
											serviceData.selectLocations.includes(option.value)
										)}
										onChange={(e) =>
											handleGenericSelectChange(e, "selectLocations")
										}
										placeholder="Select Locations"
									/>
									{formError &&
									serviceData.selectLocations?.length === 0 &&
									serviceData.zipCodesServiced?.length === 0 &&
									!paywallTriggered ? (
										<FormErrorMessage>A location is required.</FormErrorMessage>
									) : null}
								</FormControl>
							</>
						)}
						{!userPlus?.partner && (
							<FormControl
								isInvalid={
									formError &&
									serviceData.selectLocations?.length === 0 &&
									serviceData.zipCodesServiced?.length === 0 &&
									!paywallTriggered
								}
							>
								<FormLabel>Or enter zip codes you service</FormLabel>
								<MultiDisplayInput
									name="zipCodesServiced"
									placeholder="Enter zip codes"
									formData={serviceData.zipCodesServiced}
									handleFormChange={(e) => handleChange("zipCodesServiced", e)}
									isZip={true}
									// isInvalid={formError && serviceData.zipCodesServiced.length === 0}
								/>
								<FormHelperText mb="5px">
									Enter zip codes followed by enter or paste a batch of them
									separated by commas.
								</FormHelperText>
								{formError &&
								serviceData.selectLocations?.length === 0 &&
								serviceData.zipCodesServiced?.length === 0 &&
								!paywallTriggered ? (
									<FormErrorMessage>A location is required.</FormErrorMessage>
								) : null}
							</FormControl>
						)}
						{userPlus?.partner === "HPV" && userPlus.isAdmin && (
							<FormControl
								isInvalid={
									formError &&
									serviceData.selectLocations?.length === 0 &&
									serviceData.zipCodesServiced?.length === 0 &&
									!paywallTriggered
								}
							>
								<FormLabel> Select Zip Codes Serviced</FormLabel>
								<Select
									isMulti
									isDisabled={stateSelection === null}
									options={CharlotteZipCodes}
									value={CharlotteZipCodes.filter((option) =>
										serviceData.zipCodesServiced.includes(option.value)
									)}
									onChange={(e) =>
										handleGenericSelectChange(e, "zipCodesServiced")
									}
									placeholder="Select Zip Codes"
								/>
								{formError &&
								serviceData.selectLocations?.length === 0 &&
								serviceData.zipCodesServiced?.length === 0 &&
								!paywallTriggered ? (
									<FormErrorMessage>A location is required.</FormErrorMessage>
								) : null}
							</FormControl>
						)}
						<FormControl>
							<FormLabel> Create coupon</FormLabel>
							{userPlus.partner === "HPV" && (
								<FormHelperText mb="10px">
									Home Pro Valet vendors are required to have a default 10%
									discount applied to their services
								</FormHelperText>
							)}

							<RadioGroup
								onChange={(e) => handleChange("couponCode", e)}
								value={serviceData.couponCode}
							>
								<Stack direction="column">
									<Radio
										value="percent"
										defaultChecked={userPlus.partner === "HPV"}
									>
										Percentage discount when client books
									</Radio>
									{!userPlus.partner && (
										<Radio value="dollar">
											Dollar amount discount when client books
										</Radio>
									)}
									{!userPlus.partner && (
										<Radio value="none">No coupon for this service</Radio>
									)}
								</Stack>
							</RadioGroup>
						</FormControl>
						{serviceData.couponCode === "percent" && (
							<FormControl
								isInvalid={formError && serviceData.couponPercent < 10}
							>
								<FormLabel>Percentage Off</FormLabel>
								<InputGroup>
									<InputLeftElement
										pointerEvents="none"
										color="gray.300"
										fontSize="1.2em"
										children="%"
									/>
									<Input
										bgColor="white"
										type="number"
										name="description"
										value={serviceData.couponPercent}
										min={userPlus.partner === "HPV" ? 10 : undefined}
										onChange={(e) => {
											handleChange("couponPercent", e.target.value)
										}}
									/>
								</InputGroup>
								<FormHelperText mb="5px">
									Clients can redeem this coupon when they receive a job
									response from your business for this service. Coupons are only
									valid for 30 days after a client redeems and books your
									service.
								</FormHelperText>
								{formError && serviceData.couponPercent < 10 && (
									<FormErrorMessage>
										A minimum of 10% coupon is required.
									</FormErrorMessage>
								)}
							</FormControl>
						)}
						{serviceData.couponCode === "dollar" && (
							<FormControl>
								<FormLabel>Discount Amount</FormLabel>
								<InputGroup>
									<InputLeftElement
										pointerEvents="none"
										color="gray.300"
										fontSize="1.2em"
										children="$"
									/>
									<Input
										bgColor="white"
										type="text"
										name="description"
										value={serviceData.couponAmount}
										onChange={(e) => {
											handleChange("couponAmount", e.target.value)
										}}
									/>
								</InputGroup>
								<FormHelperText mb="5px">
									Clients can redeem this coupon when they receive a job
									response from your business for this service. Coupons are only
									valid for 30 days after a client redeems and books your
									service.
								</FormHelperText>
							</FormControl>
						)}
						<FormControl>
							<FormLabel>
								Customize coupon disclaimer and requirements
							</FormLabel>
							<Input
								bgColor="white"
								type="text"
								name="disclaimer"
								value={serviceData.disclaimerText}
								onChange={(e) => {
									handleChange("disclaimerText", e.target.value)
								}}
								placeholder="Optional"
							/>
						</FormControl>
						{!userPlus.partner && (
							<>
								<Text fontWeight="semibold" mt="30px" w="100%">
									Invite agents, colleagues and people you work with!
								</Text>
								<Text>
									{`Only people and teams you share with will be able to view all your
							services on ${
								partner ? partner.name : "Quiplo"
							}. Don’t worry, you can always invite more
							individuals.`}
								</Text>

								<FormControl>
									<FormLabel>Email addresses</FormLabel>
									<ReactMultiEmail
										placeholder="Enter emails to share your service (optional)"
										emails={inviteEmails}
										onChange={(_emails: string[]) => {
											setInviteEmails(_emails)
										}}
										autoFocus={true}
										onFocus={() => setFocused(true)}
										onBlur={() => setFocused(false)}
										getLabel={(email, index, removeEmail) => {
											return (
												<div data-tag key={index}>
													<div data-tag-item>{email}</div>
													<span
														data-tag-handle
														onClick={() => removeEmail(index)}
													>
														×
													</span>
												</div>
											)
										}}
									/>

									<FormHelperText mb="5px">
										You can paste multiple emails at once.
									</FormHelperText>
									{formError && serviceData.inviteEmails?.length === 0 ? (
										<FormErrorMessage>
											An invite email is required.
										</FormErrorMessage>
									) : null}
								</FormControl>
							</>
						)}

						<Button
							onClick={handleSubmit}
							isDisabled={paywallTriggered}
							w="100%"
							bgColor="borderColor"
							color="white"
							mt="30px"
							mb={{ base: "20px", lg: "60px" }}
						>
							{service ? "Update Service" : "Add Service"}
						</Button>
					</Center>
				</Box>
			</Center>
			{paywallTriggered && (
				<VendorPayWallModal isOpen={modal} setPaywallTriggered={setModal} />
			)}
		</>
	)
}
