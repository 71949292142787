import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
import {
	Box,
	Button,
	FormControl,
	Heading,
	Input,
	Image,
	Link,
	Text,
	VStack,
} from "@chakra-ui/react"
import { auth, sendPasswordReset } from "../firebase"
import { RegistrationCard } from "../Components/RegistrationCard"

function Reset() {
	const [email, setEmail] = useState("")
	const [user, loading] = useAuthState(auth)
	const [partner, setPartner] = useState(null)
	const navigate = useNavigate()

	useEffect(() => {
		if (loading) return
		if (user) navigate("/dashboard")
	}, [user, loading])

	useEffect(() => {
		const url = window.location.href
		if (url.includes("homeprovalet")) {
			setPartner("HPV")
		}
	}, [])

	return (
		<>
			<Box
				ml={{ base: "10px", sm: "40px", md: "96px" }}
				my={{ base: "15px", md: "40px" }}
				display="flex"
				alignItems="flex-end"
			>
				<Link to="/">
					{partner === "HPV" ? (
						<Image src="/assets/HPV.svg" alt="logo" />
					) : (
						<Image src="/assets/logo_with_name.svg" alt="logo" />
					)}
				</Link>
			</Box>
			<Box display={{ md: "flex" }} alignItems={{ md: "stretch" }}>
				<Box flex={1}>
					<Heading
						as="h2"
						ml={{ base: "10px", sm: "40px", md: "96px" }}
						mr={{ base: "40px" }}
						fontSize="24px"
						mt={{ base: "15px", sm: "30px" }}
						fontWeight="600"
					>
						{" "}
						Forgot your password? We're here to help.
					</Heading>
					<Box
						ml={{ base: "10px", sm: " 40px", md: "96px" }}
						mr={{ base: "10px", sm: " 40px", md: "96px" }}
						my={{ base: "15px", md: "30px" }}
					>
						<FormControl>
							<VStack gap={2} alignItems="left">
								<Input
									type="text"
									bg="white"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Email"
								/>
								<Button
									w="100%"
									bgColor="white"
									fontWeight="bold"
									border="
                #3690D8 solid"
									onClick={() => sendPasswordReset(email)}
								>
									Send Reset Link
								</Button>
								<Text fontSize="15px">
									Don't have an account?{" "}
									<Link as={NavLink} to={"/register"}>
										Register
									</Link>
								</Text>
							</VStack>
						</FormControl>
					</Box>
				</Box>
				<Box
					flex={1}
					style={{
						backgroundImage:
							"linear-gradient(to bottom, #fafafa 1%, rgba(245, 246, 252, 0.01)), url(/assets/stock_house_login.png)",
						backgroundSize: "cover",
						height: "100vh",
					}}
					pt={{ base: "20px", md: "0px" }}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						pr="10px"
						pl="10px"
					>
						{partner === "HPV" && (
							<>
								<RegistrationCard
									icon="/assets/HPV_clipboard_icon.svg"
									heading="Real Estate Partners"
									text="Elevate your real estate service by offering clients a seamless transition with our network of pre-screened, trusted home service professionals, making every move-in experience stree-free and exceptional."
								/>
								<RegistrationCard
									icon="/assets/HPV_todo_icon.svg"
									heading="Vendors"
									text="Join Home Pro Valet to tap into a steady stream of high-quality referrals from homeowners and real estate partners, actively seeking trusted vendors, boosting your credibility and expanding your customer base."
								/>
								<RegistrationCard
									icon="/assets/HPV_recommend_icon.svg"
									heading="Homeowners"
									text="Access a network of local home service providers who have passed our rigorous vetting process, ensuring quality and reliability every time."
								/>
							</>
						)}
						{!partner && (
							<>
								<RegistrationCard
									icon="/assets/todo_icon.svg"
									heading="Organize preferred Vendors as a Real Estate Agent"
									text="Manage all of your trusted vendors, scheduling time, and track all job requests in one place."
								/>
								<RegistrationCard
									icon="/assets/clipboard_icon.svg"
									heading="Grow your business as a skilled Vendor"
									text="Grow your business with trusted leads, repeat business, and NO lead fees."
								/>
								<RegistrationCard
									icon="/assets/recommend_icon.svg"
									heading="Share referrals with homeowners"
									text="Invite clients to have access to your skilled vendor network to stay top of mind."
								/>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Reset
