import React, { createContext, useContext, useState, useEffect } from "react"
import { ChakraProvider } from "@chakra-ui/react"
import quiploTheme from "../quiploTheme"
import hpvTheme from "../hpvTheme"
import blackdomeTheme from "../blackdomeTheme"
import { UserContext } from "./UserContext.js"

const ThemeContext = createContext()

export const useTheme = () => useContext(ThemeContext)

export const ThemeProvider = ({ children }) => {
	const { userPlus } = useContext(UserContext)
	const [theme, setTheme] = useState(quiploTheme)

	useEffect(() => {
		if (userPlus && userPlus.partner === "HPV") {
			setTheme(hpvTheme)
		} else if (userPlus && userPlus.partner === "BDPS") {
			setTheme(blackdomeTheme)
		} else {
			setTheme(theme)
		}
	}, [userPlus])

	return (
		<ThemeContext.Provider value={{ theme }}>
			<ChakraProvider theme={theme}>{children}</ChakraProvider>
		</ThemeContext.Provider>
	)
}
