import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { collection, getDocs, query, where } from "firebase/firestore"
import { auth, registerWithEmailAndPassword, db } from "../firebase"
import {
	Box,
	Card,
	Container,
	Center,
	Divider,
	Flex,
	Heading,
	Image,
	Link,
	Text,
	Stack,
	Spinner,
} from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext.js"
import { RegistrationFirstStep } from "../Components/RegistrationFirstStep"
import { RegistrationSecondStep } from "../Components/RegistrationSecondStep"
import { VendorRegistrationSecondStep } from "../Components/VendorRegistrationSecondStep"
import { AgentProfileInfo } from "../Components/AgentProfileInfo"
import { RegistrationCard } from "../Components/RegistrationCard"
import { teamIdDefaultIds } from "../Options/DefaultTeamIds.js"
import { formatPhoneNumber } from "../Utilities"
import { Partners } from "../partnershipData"
import config from "../config.js"

export const Register = () => {
	const { id } = useParams()
	const [partner, setPartner] = useState(null)
	const navigate = useNavigate()
	const [user, loading] = useAuthState(auth)
	const { userPlus } = useContext(UserContext)
	const [step, setStep] = useState(1)
	const [formData, setFormData] = useState({
		email: null,
		password: null,
		userType: null,
		name: null,
		phoneNumber: null,
	})
	const [agentInfo, setAgentInfo] = useState(null)
	const [team, setTeam] = useState(null)
	const [searchParams] = useSearchParams()

	useEffect(() => {
		const url = window.location.href
		if (url.includes("homeprovalet")) {
			setPartner("HPV")
		}
	}, [])

	const partnerData = Partners.find((p) => p.id === userPlus?.partner)
	const subject = partnerData ? `${partnerData.name} Inquiry` : "Quiplo Inquiry"

	useEffect(() => {
		const userTypeFromURL = searchParams.get("userType")

		setFormData((prevData) => ({
			...prevData,
			userType: userTypeFromURL || prevData.userType,
		}))

		if (userTypeFromURL) {
			setStep(2)
		}
	}, [searchParams])

	useEffect(() => {
		if (id && id.length > 10) {
			const getAgentInfo = async () => {
				const q = query(
					collection(db, "publicAgentProfile"),
					where("id", "==", id)
				)
				const docs = await getDocs(q)
				if (docs.docs.length === 0) {
					setAgentInfo(null)
				} else {
					setAgentInfo(docs.docs[0].data())
				}
			}
			getAgentInfo()
		}
	}, [id])

	useEffect(() => {
		const foundTeam =
			teamIdDefaultIds.find((team) => team.teamId === agentInfo?.teamId) ||
			teamIdDefaultIds.find((team) => team.teamId === id) ||
			null
		setTeam(foundTeam)
	}, [id, agentInfo])

	const register = async () => {
		if (partner === "HPV" && config.baseURL.includes("app.homeprovalet.com")) {
			await axios.post(`${config.baseURL}/api/notify-slack-hpv-user`, {
				name: formData.name,
				email: formData.email,
				userType: formData.userType,
				notifType: "register",
			})
		}
		registerWithEmailAndPassword(formData, id, partner)
	}

	const nextStep = async () => {
		setStep(2)
	}

	const handleChange = (name, e) => {
		setFormData({ ...formData, [name]: e })
	}

	useEffect(() => {
		if (loading) return
		if (
			user &&
			userPlus !== "pending" &&
			userPlus !== null &&
			userPlus.userType &&
			userPlus.userType !== null
		) {
			if (userPlus.userType === "vendor") {
				navigate("/job-requests")
			} else {
				navigate("/vendors")
			}
		}
	}, [user, loading, userPlus])

	if (loading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	return (
		<>
			<Box
				ml={{ base: "10px", sm: "40px", md: "96px" }}
				my={{ base: "15px", md: "40px" }}
				display="flex"
				flexGrow={1}
				alignItems="flex-end"
				width="100%"
			>
				<Link to="/">
					<Flex>
						<Image
							src={
								team
									? team.logo_path
									: partner === "HPV"
									? "/assets/HPV.svg"
									: process.env.PUBLIC_URL + "/assets/logo_with_name.svg"
							}
						/>{" "}
						{team ? (
							<>
								<Divider
									orientation="vertical"
									borderColor="coolGray.400"
									borderWidth={1}
									height="80px"
									ml="20px"
									mr="20px"
									alignSelf="center"
								/>
								<Image
									src={
										partner === "HPV"
											? "/assets/HPV.svg"
											: "/assets/logo_with_name.svg"
									}
								/>
							</>
						) : null}
					</Flex>
				</Link>
			</Box>
			<Box display={{ md: "flex" }} alignItems={{ md: "stretch" }}>
				<Box flex={1}>
					{step === 1 && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							{agentInfo ? (
								"Get access to my preferred vendors for your home services needs!"
							) : (
								<>
									{partner === "HPV"
										? "Empowering every home with expert services delivered by trusted professionals"
										: "The home services exchange for real estate agents, vendors and homeowners!"}
								</>
							)}
						</Heading>
					)}
					{step === 2 && formData.userType === "client" && (
						<>
							<Heading
								as="h2"
								ml={{ base: "10px", sm: "40px", md: "96px" }}
								mr={{ base: "40px" }}
								fontSize="24px"
								mt={{ base: "15px", sm: "30px" }}
								fontWeight="600"
							>
								{" "}
								{partner
									? "Empowering your home with expert services delivered by trusted professionals."
									: "Get access to your real estate agent's preferred vendors for your home services needs!"}
							</Heading>
							{partner && (
								<Heading
									ml={{ base: "10px", sm: "40px", md: "96px" }}
									mr={{ base: "40px" }}
									fontSize="16px"
									mt={{ base: "15px", sm: "30px" }}
									fontWeight="600"
								>
									Sign up for a Free 1-year Membership.
								</Heading>
							)}
						</>
					)}
					{step === 2 && formData.userType !== "client" && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							{`Sign up for free to grow your business with ${
								id === "HPV" || partner === "HPV" ? "Home Pro Valet" : " Quiplo"
							}!`}
						</Heading>
					)}
					<Box
						ml={{ base: "10px", sm: " 40px", md: "96px" }}
						mr={{ base: "10px", sm: " 40px", md: "96px" }}
						my={{ base: "15px", md: "30px" }}
					>
						{agentInfo && (
							<Card
								my="20px"
								display={["flex", "flex", "none", "none"]}
								flexDirection="row"
								maxW="xl"
								p="20px"
							>
								{agentInfo.photo && (
									<Image
										src={agentInfo.photo}
										alt="agent"
										borderRadius="full"
										w="65px"
										h="65px"
									/>
								)}

								<Container>
									<Heading size="md" fontWeight="bold" pb="5px">
										<strong>{agentInfo.name}</strong>
									</Heading>
									<Text>{formatPhoneNumber(agentInfo.phoneNumber)}</Text>
									<Link
										mt={2}
										color="blue.500"
										href={`mailto:${agentInfo.email}?subject=${subject}}`}
									>
										Email {agentInfo.name.split(" ")[0]}
									</Link>
								</Container>
							</Card>
						)}
						{step === 1 && (
							<RegistrationFirstStep
								formData={formData}
								nextStep={nextStep}
								handleChange={handleChange}
								id={id}
								partner={partner}
							/>
						)}
						{step === 2 && formData.userType !== "vendor" && (
							<RegistrationSecondStep
								formData={formData}
								setFormData={setFormData}
								handleChange={handleChange}
								register={register}
								id={id}
								partner={partner}
							/>
						)}
						{step === 2 && formData.userType === "vendor" && (
							<VendorRegistrationSecondStep
								formData={formData}
								setFormData={setFormData}
								handleChange={handleChange}
								register={register}
								id={id}
								partner={partner}
							/>
						)}
					</Box>
				</Box>
				<Box
					flex={1}
					style={{
						backgroundImage: `linear-gradient(to bottom, #fafafa 1%, rgba(245, 246, 252, 0.01)), url(${process.env.PUBLIC_URL}/assets/stock_house_login.png)`,
						backgroundSize: "cover",
						height: "100vh",
					}}
					pt={{ base: "20px", md: "0px" }}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						pr="10px"
						pl="10px"
					>
						{agentInfo && (
							<>
								<Card
									mt="20px"
									display={["none", "none", "flex", "flex"]}
									flexDirection="row"
									maxW="xl"
									minW="xl"
									p="20px"
								>
									{agentInfo.photo && (
										<Image
											src={agentInfo.photo}
											alt="agent"
											borderRadius="full"
											w="65px"
											h="65px"
										/>
									)}
									<Container>
										<Heading size="md" fontWeight="bold" pb="5px">
											<strong>{agentInfo.name}</strong>
										</Heading>
										<Text>{formatPhoneNumber(agentInfo.phoneNumber)}</Text>
										<Link
											mt={2}
											color="blue.500"
											href={`mailto:${agentInfo.email}?subject=${subject}`}
										>
											Email {agentInfo.name.split(" ")[0]}
										</Link>
									</Container>
								</Card>
								<AgentProfileInfo agentInfo={agentInfo} />
							</>
						)}
						{!agentInfo && partner === "HPV" && step === 1 && (
							<>
								<RegistrationCard
									icon="/assets/HPV_clipboard_icon.svg"
									heading="Real Estate Partners"
									text="Elevate your real estate service by offering clients a seamless transition with our network of pre-screened, trusted home service professionals, making every move-in experience stree-free and exceptional."
								/>
								<RegistrationCard
									icon="/assets/HPV_todo_icon.svg"
									heading="Vendors"
									text="Join Home Pro Valet to tap into a steady stream of high-quality referrals from homeowners and real estate partners, actively seeking trusted vendors, boosting your credibility and expanding your customer base."
								/>
								<RegistrationCard
									icon="/assets/HPV_recommend_icon.svg"
									heading="Homeowners"
									text="Access a network of local home service providers who have passed our rigorous vetting process, ensuring quality and reliability every time."
								/>
							</>
						)}
						{!agentInfo && !partner && (
							<>
								<RegistrationCard
									icon="/assets/todo_icon.svg"
									heading="Organize preferred Vendors as a Real Estate Agent"
									text="Manage all of your trusted vendors, scheduling time, and track all job requests in one place."
								/>
								<RegistrationCard
									icon="/assets/clipboard_icon.svg"
									heading="Grow your business as a skilled Vendor"
									text="Grow your business with trusted leads, repeat business, and NO lead fees."
								/>
								<RegistrationCard
									icon="/assets/recommend_icon.svg"
									heading="Share referrals with homeowners"
									text="Invite clients to have access to your skilled vendor network to stay top of mind."
								/>
							</>
						)}
						{!agentInfo &&
							partner === "HPV" &&
							step === 2 &&
							formData.userType === "vendor" && (
								<>
									<RegistrationCard
										icon="/assets/HPV_todo_icon.svg"
										heading="Exclusive referrals from verified homewoners"
										text="Gain direct access to pre-qualified homeowners actively seeking your expert services."
									/>
									<RegistrationCard
										icon="/assets/HPV_clipboard_icon.svg"
										heading="Boost your close rates & lower acquisition costs"
										text="Leverage our trusted network to achieve higher conversion rates and reduce customer acquisition expenses."
									/>
									<RegistrationCard
										icon="/assets/HPV_recommend_icon.svg"
										heading="Elevate your credibility and build trust"
										text="Align your brand with Home Pro Valet to enhance your reputation and become the go-to choice for homeowners."
									/>
								</>
							)}
						{!agentInfo &&
							partner === "HPV" &&
							step === 2 &&
							formData.userType === "agent" && (
								<>
									<RegistrationCard
										icon="/assets/HPV_todo_icon.svg"
										heading="Give the gift of peace of mind"
										text="Tap into our expansive network of local, vetted, quality Home Pros across 50+ home categories, ensuring your clients receive expert care for every home need."
									/>
									<RegistrationCard
										icon="/assets/HPV_clipboard_icon.svg"
										heading="Elevate your brand through co-branding"
										text="Partner with us to boost your name recognition and stay top-of-mind long after closing with co-branded marketing that resonates with homeowners."
									/>
									<RegistrationCard
										icon="/assets/HPV_recommend_icon.svg"
										heading="Stand out in a crowded market"
										text="Differentiate your services by offering exclusive access to premium home solutions that set you apart from the competition."
									/>
								</>
							)}
						{!agentInfo &&
							partner === "HPV" &&
							step === 2 &&
							formData.userType === "client" && (
								<>
									<RegistrationCard
										icon="/assets/HPV_todo_icon.svg"
										heading="Discover local excellence"
										text="Connect instantly with a local network of vetted, quality Home Pros dedicated to your home's care."
									/>
									<RegistrationCard
										icon="/assets/HPV_clipboard_icon.svg"
										heading="Streamline your home services"
										text="Save time with seamless scheduling and immediate access to trusted professionals"
									/>
									<RegistrationCard
										icon="/assets/HPV_recommend_icon.svg"
										heading="Unlock exclusive savings"
										text="Enjoy a 10% discount on every service, making quality home care more afforable."
									/>
								</>
							)}
					</Box>
				</Box>
			</Box>
		</>
	)
}
