import { Box, Button, Text } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

export const HomeOwnerFlywheelBanner = () => {
	return (
		<>
			<Box
				w="100%"
				bgColor="blue.200"
				display={{ md: "flex" }}
				justifyContent="space-between"
				p="20px"
			>
				<Box>
					<Box display="flex" alignItems="center">
						<Text color="blue.700" fontWeight="semibold" fontSize="xl">
							Share referrals with friends & family
						</Text>
					</Box>
					<Text>
						Help friends, family, and neighbors with local skilled vendors you
						and real estate agent trusts.
					</Text>
				</Box>

				<Button
					as={NavLink}
					bgColor="blue.700"
					color="white"
					borderRadius="full"
					w={{ base: "100%", md: "auto" }}
					mt={{ base: "10px", md: "0px" }}
					textDecoration="none"
					size="lg"
					to="/invite"
				>
					Share
				</Button>
			</Box>
		</>
	)
}
