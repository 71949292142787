import React, { useContext } from "react"
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Skeleton,
} from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext"
import { Partners } from "../partnershipData"

export const SkeletonTable = ({ variant }) => {
	const { userPlus } = useContext(UserContext)

	const partner = Partners.find((p) => p.id === userPlus?.partner)
	const renderSkeletonRow = () => {
		return (
			<Tr key={Math.random()}>
				<Td>
					<Skeleton height="20px" width="100px" />
				</Td>
				<Td>
					<Skeleton height="20px" width="100px" />
				</Td>
				<Td>
					<Skeleton height="20px" width="100px" />
				</Td>
				<Td>
					<Skeleton height="20px" width="100px" />
				</Td>
				<Td>
					<Skeleton height="20px" width="100px" />
				</Td>
			</Tr>
		)
	}

	const renderSkeletonCard = () => {
		return (
			<Card mb="20px" h="160px" key={Math.random()}>
				<CardHeader>
					<Skeleton height="20px" />
					<Skeleton height="20px" />
				</CardHeader>
				<CardBody>
					<Skeleton height="20px" />
					<Skeleton height="20px" />
				</CardBody>
			</Card>
		)
	}

	return (
		<>
			<Box p={4} display={["none", "none", "none", "flex"]}>
				<Table variant="simple">
					<Thead>
						<Tr>
							{variant === "vendorList" ? (
								<>
									<Th>
										{" "}
										{userPlus?.isAdmin
											? partner
												? `Team Preferred ${partner.vendorTextSingular}`
												: "Team Preferred Vendor"
											: partner
											? `My Preferred ${partner.vendorTextSingular}`
											: "My Preferred Vendor"}
									</Th>
									<Th>Company Name</Th>
									<Th>Service Type</Th>
									<Th>Number of Job Responses</Th>
									<Th>Google Review</Th>
								</>
							) : variant === "insightList" ? (
								<>
									<Th>Last Login</Th>
									<Th>Clients</Th>
									<Th>Invite Sent</Th>
									<Th>Job Requests</Th>
									<Th>Client Activity</Th>
								</>
							) : (
								<>
									<Th>Location Address</Th>
									<Th>Service Type</Th>
									<Th>Preferred Dates</Th>
									<Th>
										{partner
											? `${partner.vendorTextSingular} Responses`
											: "Vendor Responses"}
									</Th>
									<Th>Job Status</Th>
								</>
							)}
						</Tr>
					</Thead>
					<Tbody>
						{[...Array(10)].map((_, index) => renderSkeletonRow(index))}
					</Tbody>
				</Table>
			</Box>
			<Box display={["block", "block", "block", "none"]} mt="10px">
				{[...Array(10)].map((_, index) => renderSkeletonCard(index))}
			</Box>
		</>
	)
}
