import { useContext } from "react"
import { Box, Image, Text } from "@chakra-ui/react"
import { Partners } from "../partnershipData"
import { UserContext } from "../Contexts/UserContext"

export const EmptyState = ({ helperText }) => {
	const { userPlus } = useContext(UserContext)
	const partner = Partners.find((p) => p.id === userPlus?.partner)
	return (
		<Box w="100%" m="auto" pb="40px">
			<Image
				src={
					partner
						? `/assets/${partner.id}_house_landscape.svg`
						: "/assets/house_landscape.png"
				}
				alt="empty state"
				m="auto"
			/>
			<Text textAlign="center">{helperText}</Text>
		</Box>
	)
}
