import React, { useRef, useCallback } from "react"
import {
	Box,
	Text,
	Button,
	VStack,
	HStack,
	Icon,
	Input,
	List,
	ListItem,
	Flex,
	IconButton,
	useColorModeValue,
	Badge,
} from "@chakra-ui/react"
import { AddIcon, CloseIcon } from "@chakra-ui/icons"

export const FileUpload = ({
	formData,
	setFormData,
	maxFileSize = 30,
	acceptedTypes = ["image/jpeg", "image/png", "application/pdf"],
	fieldName = "files",
}) => {
	const fileInputRef = useRef(null)
	const borderColor = useColorModeValue("gray.200", "gray.600")
	const activeBorderColor = useColorModeValue("borderColor")
	const activeBgColor = useColorModeValue("gray.100", "gray.600")

	// Get current files from formData
	const files = formData[fieldName] || []

	const validateAndUpdateFiles = (newFilesToAdd) => {
		let hasError = false
		let errorMessage = ""

		// Validate files
		const validFiles = Array.from(newFilesToAdd).filter((file) => {
			// Check file type
			if (!acceptedTypes.includes(file.type)) {
				hasError = true
				errorMessage = `File type ${file.type} is not supported. Please upload JPEG, PNG or PDF files.`
				return false
			}

			// Check file size
			if (file.size > maxFileSize * 1024 * 1024) {
				hasError = true
				errorMessage = `File "${file.name}" exceeds the maximum size of ${maxFileSize}MB.`
				return false
			}

			return true
		})

		if (hasError) {
			// You can handle error states in many ways - this is one approach
			alert(errorMessage)
			return
		}

		// Update formData with the new files
		const updatedFiles = [...files, ...validFiles]
		setFormData((prevData) => ({
			...prevData,
			[fieldName]: updatedFiles,
		}))
	}

	const handleDrop = useCallback(
		(e) => {
			e.preventDefault()
			validateAndUpdateFiles(e.dataTransfer.files)
		},
		[formData, fieldName]
	)

	const handleDragOver = useCallback((e) => {
		e.preventDefault()
	}, [])

	const handleDragLeave = useCallback((e) => {
		e.preventDefault()
	}, [])

	const handleFileChange = (e) => {
		validateAndUpdateFiles(e.target.files)
	}

	const handleRemoveFile = (index) => {
		const updatedFiles = [...files]
		updatedFiles.splice(index, 1)
		setFormData((prevData) => ({
			...prevData,
			[fieldName]: updatedFiles,
		}))
	}

	const openFileDialog = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	return (
		<Box width="100%">
			<Box
				border="1px"
				borderStyle="dashed"
				borderColor={borderColor}
				borderRadius="md"
				bg="white"
				p={4}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				textAlign="center"
				cursor="pointer"
				transition="all 0.2s"
				_hover={{ borderColor: activeBorderColor, bg: activeBgColor }}
			>
				<Input
					type="file"
					multiple
					accept={acceptedTypes.join(",")}
					onChange={handleFileChange}
					ref={fileInputRef}
					display="none"
				/>

				<VStack spacing={2} py={4} onClick={openFileDialog}>
					<Icon
						as={AddIcon}
						color="borderColor"
						boxSize={6}
						p={1}
						border="1px"
						borderColor="borderColor"
						borderRadius="md"
					/>
					<Text color="gray.600" fontWeight="medium">
						Upload or drag and drop attachments
					</Text>
					<Text color="gray.500" fontSize="sm">
						Max. File Size: {maxFileSize}MB
					</Text>

					<Button size="md" mt={2}>
						Add attachments
					</Button>
				</VStack>
			</Box>

			{files.length > 0 && (
				<List spacing={2} mt={4}>
					{files.map((file, index) => (
						<ListItem
							key={`${file.name}-${index}`}
							p={2}
							bg="white"
							borderRadius="md"
							boxShadow="sm"
						>
							<Flex justify="space-between" align="center">
								<HStack spacing={2}>
									<Text fontWeight="medium" noOfLines={1} maxW="200px">
										{file.name}
									</Text>
									<Badge colorScheme="gray" fontSize="xs">
										{(file.size / (1024 * 1024)).toFixed(2)} MB
									</Badge>
								</HStack>
								<IconButton
									icon={<CloseIcon />}
									size="xs"
									variant="ghost"
									colorScheme="red"
									onClick={() => handleRemoveFile(index)}
									aria-label="Remove file"
								/>
							</Flex>
						</ListItem>
					))}
				</List>
			)}
		</Box>
	)
}
