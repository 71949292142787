import { useContext, useState } from "react"
import { Box, Button, Image, Link, Text } from "@chakra-ui/react"
import { logUserEvent } from "../firebase"
import { UserContext } from "../Contexts/UserContext"
import { analytic_events } from "../analytics"
import { VendorPayWallModal } from "./VendorPayWall"
import { Partners } from "../partnershipData"

export const PaywallBanner = ({ location }) => {
	const { userPlus } = useContext(UserContext)
	const [modal, setModal] = useState(false)

	const partner = Partners.find((p) => p.id === userPlus?.partner)

	const handleClick = async () => {
		await logUserEvent(analytic_events.OPEN_PAYWALL, userPlus.id, {
			userType: userPlus.userType,
			location: location,
		})
		setModal(true)
	}
	return (
		<>
			<Box
				w="100%"
				bgColor="blue.200"
				display={{ md: "flex" }}
				justifyContent="space-between"
				p="20px"
			>
				<Box>
					<Box display="flex" alignItems="center">
						<Image
							src="/assets/verified_badge.svg"
							color="blue.700"
							my="auto"
							mr="10px"
						/>
						<Text color="blue.700" fontWeight="semibold" fontSize="xl">
							Upgrade your {partner ? partner.name : "Quiplo"} account
						</Text>
					</Box>
					<Text>
						{userPlus.userType === "vendor" &&
							"Unlock the ability to respond to job requests and have your business marked as verified so agents and homeowners know you are responsive."}
						{userPlus.userType === "agent" && !userPlus.partner && (
							<>
								Contact Quiplo to setup your team, white-label your client
								portal, and organize your vendor list.{" "}
								<Link
									href="https://calendly.com/quiploteams/30min"
									target="_blank"
									rel="noopener noreferrer"
								>
									Schedule a call.
								</Link>
							</>
						)}
						{userPlus.userType === "agent" &&
							userPlus.partner === "HPV" &&
							"Upgrade to get access to Home Pro Valet premier services to help support and grow your business."}
					</Text>
				</Box>
				{userPlus.userType === "vendor" && (
					<Button
						bgColor="blue.700"
						color="white"
						borderRadius="full"
						w={{ base: "100%", md: "auto" }}
						mt={{ base: "10px", md: "0px" }}
						size="lg"
						onClick={handleClick}
					>
						Upgrade
					</Button>
				)}
				{userPlus.userType === "agent" && userPlus.partner === "HPV" && (
					<Button
						as="a"
						href="https://pay.homeprovalet.com/b/fZe7ve8Wr9aK3PG4gh"
						bgColor="blue.700"
						color="white"
						borderRadius="full"
						w={{ base: "100%", md: "auto" }}
						mt={{ base: "10px", md: "0px" }}
						size="lg"
						target="_blank"
						rel="noopener noreferrer"
					>
						Upgrade
					</Button>
				)}
			</Box>
			{userPlus.userType === "vendor" && modal && (
				<VendorPayWallModal
					isOpen={modal}
					setPaywallTriggered={setModal}
					location={location}
				/>
			)}
		</>
	)
}
